import { abilitiesPlugin } from '@casl/vue';
import date from 'date-fns';
import numeral from 'numeral';
import { Codemirror } from 'vue-codemirror';

import store from '@/store/index.js';

import ability from './ability';
import eventBus from './eventBus';

export default (app) => {
  // Set properties that were previously in Vue.prototype to app.config.globalProperties
  // These properties are still accessible in components through `this.$bus`, `this.$date`, etc.
  app.config.globalProperties.$bus = eventBus;
  app.config.globalProperties.$date = date;
  app.config.globalProperties.$numeral = numeral;
  // Use plugins with the app instance

  app.use(abilitiesPlugin, ability.defineAbilities(), {
    useGlobalProperties: true // Add this option to enable global properties like $ability and $can
  });
  app.component('codemirror', Codemirror);

  /**
   * Checks if the user has the given permission for an action on a subject.
   *
   * @param {string} action - The action to check (e.g., 'view').
   * @param {string} subject - The subject of the action (e.g., 'user').
   * @returns {boolean} - True if the user has permission, otherwise false.
   *
   * @example
   * // Check if the user has permission to edit banner custom content
   * this.$c('edit', 'bannerCustomContent'); // returns true or false
   */
  app.config.globalProperties.$c = function (action, subject) {
    for (let i = 0; i < store.getters.userGrants.length; i++) {
      let el = store.getters.userGrants[i];
      let [subj, act] = el.actionName.split('.');
      if (subject === subj && act === action) {
        return true;
      }
    }
    return false;
  };
};
