import date from 'date-fns';

/**
 * Converts a given value to a UTC-based Date object.
 *
 * - If the date is in "YYYY-MM-DD" format (without time), it is treated as UTC midnight.
 * - Otherwise, applies the old logic using timezone offsets.
 *
 * @param {string|number|Date} value - The date input. Can be a timestamp, date object, or "YYYY-MM-DD" string.
 * @param {boolean} negative - If true, adjusts time using negative timezone offset.
 * @returns {Date} A Date object representing the UTC time.
 */
export const toUTCDate = (value, negative) => {
  if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
    // If value is in "YYYY-MM-DD" format, ensure UTC without timezone shift
    const [year, month, day] = value.split('-').map(Number);
    return new Date(Date.UTC(year, month - 1, day));
  }

  // Otherwise, use the old logic with timezone offsets
  const timeZoneOffset = negative
    ? -new Date(value).getTimezoneOffset()
    : new Date(value).getTimezoneOffset();

  return date.addMinutes(new Date(value), timeZoneOffset);
};

export const toUTCDateISOString = (value, negative) => {
  return toUTCDate(value, negative).toISOString();
};

export const toEndDate = (date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
};

export const getLastWeek = () => {
  const today = new Date();
  return {
    start: date.getTime(date.subDays(today.setHours(0, 0, 0, 0), 6)),
    end: date.getTime(today.setHours(23, 59, 59, 0))
  };
};

export const getLastFullWeek = () => {
  const today = new Date();
  return {
    start: date.getTime(date.subDays(today.setHours(0, 0, 0, 0), 7)),
    end: date.getTime(date.subDays(today.setHours(23, 59, 59, 0), 1))
  };
};

export const secondsToDhms = (sec) => {
  const seconds = Number(sec);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days, ') : '';
  const hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};
