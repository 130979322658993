import { defineAsyncComponent } from 'vue';

const NotFound = defineAsyncComponent(
  () => import('@/views/ErrorPages/index.vue')
);

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'Page404',
    hidden: true,
    meta: { title: 'Page 404' }
  }
];

export default routes;
