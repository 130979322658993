import { createI18n } from 'vue-i18n';

import messages from './../i18n/index'; // Import your messages here

const i18n = createI18n({
  legacy: false, // Use Composition API mode instead of legacy
  globalInjection: true, // Allows usage of `$t` globally without importing
  locale: process.env.LANGUAGE,
  messages,
  pluralizationRules: {
    ru: function (choice, choicesLength) {
      if (choice === 0) return 0;

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) return !teen && endsWithOne ? 0 : 1;
      if (!teen && endsWithOne) return 1;
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2;
      return choicesLength < 4 ? 2 : 3;
    }
  }
});

export default i18n;
