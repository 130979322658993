import {
  checkGrantsPermission,
  checkPermission
} from '@/utils/checkPermission.js';

import { readToken } from './utils/auth';

export const whiteList = [
  '/login',
  '/register',
  '/forgot',
  '/confirm-reset',
  '/confirm-invite',
  '/reset-password',
  '/404',
  '/401'
];

import { useNProgress } from '@vueuse/integrations/useNProgress';

export default function createPermission({ store, router }) {
  const { start, done } = useNProgress(null, {
    minimum: 0.1,
    speed: 200,
    trickleSpeed: 300,
    showSpinner: false
  });

  const matched = router.resolve(location.pathname);
  if (
    !checkPermission(matched.meta.roles, true) &&
    !checkGrantsPermission(matched.meta)
  ) {
    store.commit('SET_PAGE_FAILURE', {
      status: 'Unauthorized',
      statusText:
        "You don't have access to this page. <br> Maybe you need to change role?"
    });
  }

  router.beforeEach((to, from, next) => {
    start();
    store.commit('SET_PAGE_FAILURE', false); // reset app error page if it exists

    const entryUrl = store.state.app.entryUrl;
    if (readToken()) {
      if (to.path === '/login') {
        next({ path: '/' });
        done();
        return;
      } else {
        if (
          checkPermission(to.meta.roles, true) ||
          checkGrantsPermission(to.meta)
        ) {
          if (entryUrl && whiteList.indexOf(entryUrl) === -1) {
            const url = entryUrl;
            store.commit('SET_ENTRY_URL', null);
            next(url);
          } else {
            next();
          }
        } else {
          store.commit('SET_PAGE_FAILURE', {
            status: 'Unauthorized',
            statusText:
              "You don't have access to this page. <br> Maybe you need to change role?"
          });
          done();
        }
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) next();
      else {
        store.commit('SET_ENTRY_URL', to.path);
        next('/login');
        done();
      }
    }
  });

  router.afterEach(() => {
    done();
  });
}
