import Vue from 'vue';

export default {
  fetchTrafficSources({ commit, state }, refresh) {
    if (state.trafficSources.length > 0 && !refresh)
      return state.trafficSources;
    return Vue.api.adnet.get(`/dict/traffic-source`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_TRAFFIC_SOURCES', response);
      return response;
    });
  },
  fetchRequestFormatTypes({ commit, state }, refresh) {
    if (state.requestFormatTypes.length > 0 && !refresh)
      return state.requestFormatTypes;
    return Vue.api.adnet.get(`/dict/request-format-types`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_REQUEST_FORMAT_TYPES', response);
      return response;
    });
  },
  fetchBidFormatTypes({ commit, state }, refresh) {
    if (state.bidFormatTypes.length > 0 && !refresh)
      return state.bidFormatTypes;
    return Vue.api.adnet.get(`/dict/bid-format-types`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_BID_FORMAT_TYPES', response);
      return response;
    });
  },
  fetchSSPBidFormatTypes({ commit, state }, refresh) {
    if (state.sspBidFormatTypes.length > 0 && !refresh)
      return state.sspBidFormatTypes;
    return Vue.api.adnet.get(`/dict/ssp-bid-format-types`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_SSP_BID_FORMAT_TYPES', response);
      return response;
    });
  },
  fetchRepeatTypes({ commit, state }, refresh) {
    if (state.repeatTypes.length > 0 && !refresh) return state.repeatTypes;
    return Vue.api.adnet.get(`/dict/campaign-repeat-types`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_REPEAT_TYPES', response);
      return response;
    });
  },
  fetchFeedProtocols({ commit, state }, refresh) {
    if (state.feedProtocols.length > 0 && !refresh) return state.feedProtocols;
    return Vue.api.adnet.get(`/dict/feed-protocols`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_FEED_PROTOCOLS', response);
      return response;
    });
  },
  fetchSSPFeedProtocols({ commit, state }, refresh) {
    if (state.feedProtocols.length > 0 && !refresh) return state.feedProtocols;
    return Vue.api.adnet.get(`/dict/ssp-feed-protocols`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_SSP_FEED_PROTOCOLS', response);
      return response;
    });
  },
  fetchNotifyTypes({ commit, state }, refresh) {
    if (state.notifyTypes.length > 0 && !refresh) return state.notifyTypes;
    return Vue.api.adnet.get(`/dict/notify-types`).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_NOTIFY_TYPES', response);
      return response;
    });
  },
  fetchAdTags({ commit, state }, data = {}) {
    if (state.adTags.length > 0 && !data.refresh) return state.adTags;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get(`/dict/ad-tags`, params).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_AD_TAGS', response);
      return response;
    });
  },
  fetchCampaignRejectReasons: (function () {
    let isGoing = null;

    return function ({ commit, state }, data = {}) {
      if (isGoing) return isGoing;

      if (state.campaignRejectReasons.length > 0 && !data.refresh) {
        return state.campaignRejectReasons;
      }

      const params = data.params ? { params: { ...data.params } } : {};

      isGoing = Vue.api.adnet
        .get(`/dsp/campaigns/reject-reasons`, params)
        .then((res) => {
          const response = res === null ? [] : res;
          commit('SET_CAMPAIGN_REJECT_REASONS', response);
          isGoing = null;

          return response;
        });

      return isGoing;
    };
  })(),
  fetchCreativeRejectReasons({ commit, state }, data = {}) {
    if (state.creativeRejectReasons.length > 0 && !data.refresh)
      return state.creativeRejectReasons;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dsp/creatives/reject-reasons`, params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_CREATIVE_REJECT_REASONS', response);
        return response;
      });
  },
  fetchLandingRejectReasons({ commit, state }, data = {}) {
    if (state.landingRejectReasons.length > 0 && !data.refresh)
      return state.landingRejectReasons;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dsp/landings/reject-reasons`, params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_LANDING_REJECT_REASONS', response);
        return response;
      });
  },
  fetchLandingPlaceholders({ commit, state }, data = {}) {
    if (state.placeholders.length > 0 && !data.refresh)
      return state.placeholders;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dsp/landings/placeholders`, params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_PLACEHOLDERS', response);
        return response;
      });
  },

  fetchCreativesStatuses({ state }) {
    return state.creativesStatuses;
  },
  fetchLandingStatuses({ commit, state }, data = {}) {
    if (state.landingStatuses.length > 0 && !data.refresh)
      return state.landingStatuses;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get(`/dsp/landings/statuses`, params).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_LANDING_STATUSES', response);
      return response;
    });
  },
  fetchCampaignStatuses({ commit, state }, data = {}) {
    if (state.campaignStatuses.length > 0 && !data.refresh)
      return state.campaignStatuses;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get(`/dsp/campaigns/statuses`, params).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_CAMPAIGN_STATUSES', response);
    });
  },
  fetchDirectDealsStatuses({ commit, state }, data = {}) {
    if (state.directDealsStatuses.length > 0 && !data.refresh)
      return state.directDealsStatuses;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dict/direct-deal/statuses', params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_DIRECT_DEALS_STATUSES', response);
        return response;
      });
  },
  fetchLandingDistributionAlgorithms({ commit, state }, data = {}) {
    if (state.landingsDistribution.length > 0 && !data.refresh)
      return state.landingsDistribution;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dsp/landings/distribution-algorithms`, params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_LANDINGS_DISTRIBUTION', response);
        return response;
      });
  },
  fetchContentTypes({ commit, state }, refresh) {
    if (state.contentTypes.length > 0 && !refresh) return state.contentTypes;
    return Vue.api.adnet.get(`/ssp/content-types`).then((res) => {
      const response = res.list === null ? [] : res.list;
      commit('SET_CONTENT_TYPES', response);
      return response;
    });
  },
  fetchAdvertCreativeTags({ commit, state }, data = {}) {
    if (Object.keys(state.advertCreativeTags).length > 0 && !data.refresh)
      return state.advertCreativeTags;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dsp/advert-creative-tags`, params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_ADVERT_CREATIVE_TAGS', response);
        return response;
      });
  },
  fetchAdPositions({ commit, state }, data = {}) {
    if (Object.keys(state.adPositions).length > 0 && !data.refresh)
      return state.adPositions;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/ad-positions', params).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_AD_POSITIONS', response);
      return response;
    });
  },
  fetchBannerDimensions({ commit, state }, data = {}) {
    if (Object.keys(state.bannerDimensions).length > 0 && !data.refresh)
      return state.bannerDimensions;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/banner-dimensions', params).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_BANNER_DIMENSIONS', response);
      return response;
    });
  },
  fetchBudgetDelivery({ commit, state }, data = {}) {
    if (Object.keys(state.budgetDelivery).length > 0 && !data.refresh)
      return state.budgetDelivery;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dsp/campaigns/budget-deliveries', params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_BUDGET_DELIVERY', response);
        return response;
      });
  },
  fetchConnectionTypes({ commit, state }, refresh) {
    if (Object.keys(state.connectionTypes).length > 0 && !refresh)
      return state.connectionTypes;
    return Vue.api.adnet.get('/dict/maxmind-connection-types').then((res) => {
      const response = res === null ? [] : res;
      commit('SET_CONNECTION_TYPES', response);
      return response;
    });
  },
  fetchCarriers({ commit, state }, refresh) {
    if (Object.keys(state.carriers).length > 0 && !refresh)
      return state.carriers;
    return Vue.api.adnet.get('/dict/maxmind-carriers').then((res) => {
      const response = res === null ? [] : res;
      commit('SET_CARRIES', response);
      return response;
    });
  },
  fetchTrafficTypesDict({ commit, state }, refresh) {
    if (Object.keys(state.trafficTypesDict).length > 0 && !refresh)
      return state.trafficTypesDict;
    return Vue.api.adnet.get('/dict/traffic-types-dict').then((res) => {
      const response = res === null ? {} : res;
      commit('SET_TRAFFIC_TYPES_DICT', response);
      return response;
    });
  },
  fetchSiteTypes({ commit, state }, refresh) {
    if (Object.keys(state.siteTypes).length > 0 && !refresh)
      return state.siteTypes;
    return Vue.api.adnet.get('/dict/site-types').then((res) => {
      const response = res === null ? {} : res;
      commit('SET_SITE_TYPES', response);
      return response;
    });
  },
  fetchStatuses({ commit, state }, refresh) {
    if (Object.keys(state.statuses).length > 0 && !refresh)
      return state.statuses;
    return Vue.api.adnet.get('/ssp/statuses').then((res) => {
      const response = res.list === null ? {} : res;
      commit('SET_STATUSES', response);
      return response;
    });
  },
  fetchSiteCategories({ commit, state }, refresh) {
    if (state.siteCategories.length > 0 && !refresh)
      return state.siteCategories;
    return Vue.api.adnet.get('/dict/categories').then((res) => {
      const response = res === null ? [] : res;
      commit('SET_SITE_CATEGORIES', response);
      return response;
    });
  },
  fetchTrafficTypes({ commit }) {
    return Vue.api.adnet.get('/dict/traffic-types').then((res) => {
      commit('SET_TRAFFIC_TYPES', res);
      return res;
    });
  },
  fetchCountries({ commit }) {
    return Vue.api.adnet.get('/dict/countries').then((res) => {
      const countries = res
        .map((country) => {
          country.name = country.shortName;
          return country;
        })
        .filter((el) => el.iso2 !== 'XX');
      commit('SET_COUNTRIES', countries);
      return countries;
    });
  },
  fetchRegions(store, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/regions', params).then((res) => {
      return res === null ? [] : res;
    });
  },
  fetchOSTypes({ commit }) {
    return Vue.api.adnet.get('/dict/os-types').then((res) => {
      commit('SET_OS_TYPES', res);
      return res;
    });
  },
  fetchOSNames({ commit }) {
    return Vue.api.adnet.get('/dict/os').then((res) => {
      commit('SET_OS_NAMES', res);
      return res;
    });
  },
  fetchOsVersions(store, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/os-major-versions', params).then((res) => {
      const response = res === null ? [] : res;
      return response;
    });
  },
  fetchTargetAdunits({ commit }) {
    return Vue.api.adnet
      .get('/targets/adunits', { params: { limit: 9999 } })
      .then((res) => {
        commit('SET_TARGETS_ADUNITS', res);
        return res;
      });
  },
  fetchLanguagesNames({ commit }) {
    return Vue.api.adnet.get('/dict/languages').then((res) => {
      commit('SET_LANGUAGES_NAMES', res);
      return res;
    });
  },
  fetchDeviceTypes({ commit }) {
    return Vue.api.adnet.get('/dict/device-types').then((res) => {
      commit('SET_DEVICE_TYPES', res);
      return res;
    });
  },
  fetchAdunitTypes({ commit, state }, refresh) {
    if (state.adUnitTypes.length > 0 && !refresh) return state.adUnitTypes;
    return Vue.api.adnet.get('/dict/adunit-types').then((res) => {
      const response = res === null ? [] : res;
      commit('SET_AD_UNIT_TYPES', response);
      return response;
    });
  },
  fetchAdunitFormats({ commit, state }, { type }) {
    if (state.adunitFormats[type] && state.adunitFormats[type].length > 0) {
      return state.adunitFormats[type];
    }
    if (!type) return [];
    return Vue.api.adnet
      .get(`/dict/adunit-types/${type}/formats`)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_ADUNIT_FORMATS', { list: response, type });
        return response;
      });
  },
  async fetchAllAdunitFormats({ commit, state, dispatch }) {
    await dispatch('fetchAdunitTypes');

    let formats = [];
    await Promise.all(
      state.adUnitTypes.map((el) => {
        return Vue.api.adnet
          .get(`/dict/adunit-types/${el.id}/formats`)
          .then((res) => {
            const response = res === null ? [] : res;
            formats = [...formats, ...response];
            commit('SET_ADUNIT_FORMATS', { list: response, type: el.id });
            return response;
          });
      })
    );

    return formats;
  },
  fetchBrowsers({ commit }) {
    return Vue.api.adnet.get('/dict/browsers').then((res) => {
      commit('SET_BROWSERS', res);
      return res;
    });
  },
  fetchBrowserTypes({ commit }) {
    return Vue.api.adnet.get('/dict/browser-types').then((res) => {
      commit('SET_BROWSER_TYPES', res);
      return res;
    });
  },
  fetchCookieTypes({ commit }) {
    return Vue.api.adnet.get('/dict/cookie-types').then((res) => {
      const response = res === null ? [] : res;
      commit('SET_COOKIE_TYPES', response);
      return response;
    });
  },
  fetchDeviceModels({ commit }) {
    return Vue.api.adnet.get('/device/models').then((res) => {
      commit('SET_DEVICE_MODELS', res.deviceModels);
    });
  },
  fetchISPs({ commit }) {
    return Vue.api.adnet.get('/isps').then((res) => {
      commit('SET_ISPS', res.isps);
    });
  },
  fetchPricingModels({ commit, state }, data = {}) {
    if (!data.adUnitTypeId) return state.pricingModels;
    if (state.pricingModels.length > 0 && !data.refresh)
      return state.pricingModels;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dict/pricing-models/adunit-types/${data.adUnitTypeId}`, params)
      .then((res) => {
        const response = res === null ? [] : res;
        commit('SET_PRICING_MODELS', response);
        return response;
      });
  },
  fetchTriggerMethods({ commit }) {
    const data = [
      {
        id: 1,
        name: 'Click anywhere'
      },
      {
        id: 2,
        name: 'Click on specific selector'
      }
    ];
    commit('SET_TRIGGER_METHODS', data);
    return data;
  },
  fetchCpaStates({ commit }) {
    return Vue.api.adnet.get('/dict/cpa-state').then((res) => {
      commit('SET_CPA_STATES', res);
      return res;
    });
  },
  fetchCpaSpotStates({ commit }) {
    return Vue.api.adnet.get('/dict/cpa-spot-state').then((res) => {
      commit('SET_CPA_SPOT_STATES', res);
      return res;
    });
  },
  fetchCpaRuleTypes({ commit }) {
    return Vue.api.adnet.get('/dict/cpa-rule-type').then((res) => {
      commit('SET_CPA_RULE_TYPES', res);
      return res;
    });
  },
  fetchCpaConditions({ commit }) {
    return Vue.api.adnet.get('/dict/cpa-condition').then((res) => {
      commit('SET_CPA_CONDITIONS', res);
      return res;
    });
  },
  fetchDealTypes({ commit }) {
    return Vue.api.adnet.get('/dict/deal-types').then((res) => {
      commit('SET_DEAL_TYPES', res);
      return res;
    });
  },
  fetchBlacklistRuleStates({ commit }) {
    return Vue.api.adnet.get('/dict/blacklist-status').then((res) => {
      commit('SET_BLACKLIST_RULE_STATES', res);
      return res;
    });
  },
  fetchBlacklistRuleTypes({ commit }) {
    return Vue.api.adnet.get('/dict/blacklist-ban-type').then((res) => {
      commit('SET_BLACKLIST_RULE_TYPES', res);
      return res;
    });
  },
  fetchBlacklistRuleConditions({ commit }) {
    return Vue.api.adnet.get('/dict/blacklist-condition').then((res) => {
      commit('SET_BLACKLIST_RULE_CONDITIONS', res);
      return res;
    });
  }
};
