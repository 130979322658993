import creativeTypes from './../../types/index';
import actions from './../actions/index';
import getters from './../getters/index';
import mutations from './../mutations/index';

const matcherState = {
  exoclick: {
    browser: [],
    os: [],
    device: [],
    deviceType: []
  },
  trafficstars: {
    browser: [],
    os: [],
    deviceType: []
  },
  adnium: {
    os: [],
    device: []
  },
  matchOs: [],
  matchBrowsers: [],
  matchDevices: [],
  matchDeviceTypes: []
};

const dictState = {
  categories: [],
  triggerMethods: [],
  budgetDelivery: [],
  creativeTypes: [],
  creativeFormats: [],
  creativesStatuses: creativeTypes.statuses,
  cookieTypes: [],
  requestFormatTypes: [],
  carriers: [],
  contentTypes: [],
  repeatTypes: [],
  trafficTypesDict: [],
  siteTypes: [],
  feedProtocols: [],
  sspFeedProtocols: [],
  adPositions: [],
  adUnitTypes: [],
  bannerDimensions: [],
  connectionTypes: [],
  deviceTypes: [],
  deviceModels: [],
  exchangeTypes: [],
  languages: [],
  siteCategories: [],
  trafficTypes: [],
  countries: [],
  statuses: {},
  browsers: [],
  browserTypes: [],
  directDealsStatuses: [],
  notifyTypes: [],
  bidFormatTypes: [],
  sspBidFormatTypes: [],
  osTypes: [],
  osNames: [],
  osVersions: [],
  isps: [],
  adTags: [],
  pricingModels: [],
  landingsDistribution: [],
  landingRejectReasons: [],
  campaignRejectReasons: [],
  placeholders: [],
  landingStatuses: [],
  adunitFormats: {},
  advertCreativeTags: [],
  campaignStatuses: [],
  creativeRejectReasons: [],
  trafficSources: [],
  cpaStates: [],
  cpaSpotStates: [],
  cpaRuleTypes: [],
  cpaConditions: [],
  blacklistRuleStates: [],
  blacklistRuleTypes: [],
  blacklistRuleConditions: [],
  dealTypes: [],
  history: []
};

const publisherState = {
  siteLabels: [],
  pubSites: [],
  adunits: [],
  sitesSettings: [],
  directDeals: [],
  syndicationDomains: [],
  blacklistAutoRules: []
};

const statsState = {
  dimensions: [],
  metrics: [],
  reports: [],
  segments: []
};

const advertState = {
  landings: [],
  advertCampaigns: {
    list: [],
    totalRows: 0
  },
  advertCreatives: [],
  landingGroups: [],
  labels: [],
  postbacks: [],
  retargetGroups: []
};

const dashboardState = {
  advDashboardWidgets: {},
  advDashboardStats: {},
  pubDashboardWidgets: {},
  pubDashboardStats: {}
};

const dspIntegrationsState = {
  arbitrageSites: [],
  dspFilters: [],
  dspFeeds: [],
  dspDirectFeedBindings: [],
  dsp: [],
  ssp: [],
  codeTemplates: [],
  exchanges: [],
  exchangePoints: [],
  dspFeedBindings: [],
  sspFeeds: [],
  dbEntities: [],
  experiments: []
};

const state = {
  ...matcherState,
  ...dictState,
  ...publisherState,
  ...statsState,
  ...advertState,
  ...dashboardState,
  ...dspIntegrationsState,
  targets: []
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
