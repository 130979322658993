import Vue from 'vue';

export default {
  fetchExperiments({ commit }) {
    return Vue.api.adnet.get('/dsp-integrations/experiments').then((res) => {
      commit('SET_EXPERIMENTS', res.list);
      return res.list;
    });
  },
  fetchCodeTemplates({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get(`/ssp/code-templates`, params).then((res) => {
      const response = res.list === null ? [] : res.list;
      commit('SET_CODE_TEMPLATES', response);
      return response;
    });
  },
  fetchDSPFilters({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dsp-integrations/filters', params)
      .then((res) => {
        const responseList = res === null ? [] : res.result;
        commit('SET_DSP_FILTERS', responseList);
        return res;
      });
  },
  fetchExchangePoints({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dsp-integrations/exchange-point', params)
      .then((res) => {
        const responseList = res === null ? [] : res.result;
        commit('SET_EXCHANGE_POINTS', responseList);
        return res;
      });
  },
  fetchDSPFeeds({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dsp-integrations/feeds', params).then((res) => {
      const responseList = res.result === null ? [] : res.result;
      commit('SET_DSP_FEEDS', responseList);
      return res;
    });
  },
  fetchDSPFeedBindings({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dsp-integrations/feed-bindings', params)
      .then((res) => {
        const responseList = res.result === null ? [] : res.result;
        commit('SET_DSP_FEED_BINDINGS', responseList);
        return res;
      });
  },
  fetchSSPFeeds({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dsp-integrations/ssp-feeds', params)
      .then((res) => {
        const responseList = res.result === null ? [] : res.result;
        commit('SET_SSP_FEEDS', responseList);
        return res;
      });
  },
  fetchDSPDirectFeedBindings({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get('/dsp-integrations/direct-feed-bindings', params)
      .then((res) => {
        const responseList = res.result === null ? [] : res.result;
        commit('SET_DSP_DIRECT_FEED_BINDINGS', responseList);
        return res;
      });
  },
  fetchDSP({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dsp-integrations/dsp', params).then((res) => {
      const responseList = res.result === null ? [] : res.result;
      commit('SET_DSP', responseList);
      return res;
    });
  },
  fetchSSP({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dsp-integrations/ssp', params).then((res) => {
      const responseList = res.result === null ? [] : res.result;
      commit('SET_SSP', responseList);
      return res;
    });
  },
  fetchExchanges({ commit, state }, refresh) {
    return Vue.api.adnet.get('/dict/exchange-points').then((res) => {
      const response = res === null ? {} : res;
      commit('SET_EXCHANGES', response);
      return response;
    });
  },
  fetchDBEntities({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/ssp/db-entity', params).then((res) => {
      const response = res.list === null ? [] : res.list;
      commit('SET_DB_ENTITIES', response);
      return response;
    });
  },
  fetchArbitrageSites({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet
      .get(`/dsp-integrations/arbitrage-traffic-sites`, params)
      .then((res) => {
        let processedRes = res;
        const responseList =
          res.result === null
            ? []
            : res.result.map((s) => {
                let site = s;
                site.name = site.domain;
                return site;
              });
        processedRes.result = responseList;
        // const responseList = res.result === null ? [] : res.result;
        commit('SET_ARBITRAGE_SITES', responseList);
        return processedRes;
      });
  },
  fetchRetargetGroups({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    // return {
    //   result: [{ id: 1, name: 'Retarget group 1' }, { id: 2, name: 'Retarget group 2' }],
    //   total: 2,
    //   offset: 0,
    //   limit: 25
    // }
    return Vue.api.adnet
      .get('/dsp-integrations/retarget-groups', params)
      .then((res) => {
        const responseList = res.result === null ? [] : res.result;
        commit('SET_RETARGET_GROUPS', responseList);
        return res;
      });
  }
};
