import { createStore } from 'vuex';

import advert from './modules/advert.js';
import app from './modules/app.js';
import bounty from './modules/bounty.js';

const store = createStore({
  strict: true,
  modules: {
    bounty,
    advert,
    app
  }
});
export default store;
