import store from '@/store/index.js';

/**
 * @param  {String|Array} value
 * @param  {Boolean} defaultPermission=false
 * @return {Boolean}
 */
export function checkPermission(value, defaultPermission = false) {
  if (value && value instanceof Array && value.length > 0) {
    return value.includes(store.getters.role);
  } else if (value && typeof value === 'string') {
    return store.getters.role === value;
  }
  return defaultPermission;
}

/**
 * @param  {Object} routeMeta
 * @return {Boolean}
 */

export function checkGrantsPermission(routeMeta) {
  if (!routeMeta.grants || !routeMeta.grants.list || !routeMeta.grants.roles)
    return false;
  if (!routeMeta.grants.roles.includes(store.getters.role)) return false;

  let hasGrants = true;
  routeMeta.grants.list.forEach((grant) => {
    if (!store.getters.user.grants.includes(grant)) {
      hasGrants = false;
      return;
    }
  });

  return hasGrants;
}
