<template>
  <pre class="d-inline text-embed">
    {{ value }}
  </pre>
</template>

<script>
import { useToastController } from 'bootstrap-vue-next';

// remove clipboard, need rework
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    canCopy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      toastController: useToastController()
    };
  },

  methods: {
    onCopySuccess() {
      this.toastController.show?.({
        props: {
          body: `You just copied: ${this.value}`,
          autoHideDelay: 5000
        }
      });
    }
  }
};
</script>
