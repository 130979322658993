import {
  vBModal,
  vBPopover,
  vBScrollspy,
  vBToggle,
  vBTooltip
} from 'bootstrap-vue-next';

import vClickOutside from '@/directives/clickOutsideDirective.js';

export default function registerDirectives(app) {
  // Register Bootstrap Vue directives
  app.directive('b-modal', vBModal);
  app.directive('b-popover', vBPopover);
  app.directive('b-scrollspy', vBScrollspy);
  app.directive('b-toggle', vBToggle);
  app.directive('b-tooltip', vBTooltip);

  // Register custom click-outside directive
  app.directive('click-outside', vClickOutside);

  // Register custom focus directive
  app.directive('focus', {
    mounted(el) {
      el.focus();
    }
  });
}
