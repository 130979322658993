import {
  AD_TYPE_INTERSTITIAL,
  AD_TYPE_LINK,
  AD_TYPE_POPUNDER,
  utcTimezones
} from '@/types/index';

export default {
  rejectReasons: (state) => state.rejectReasons,
  landingRejectReasons: (state) => state.landingRejectReasons,
  campaignRejectReasons: (state) => state.campaignRejectReasons,
  placeholders: (state) => state.placeholders,
  adTags: (state) => state.adTags,
  budgetDelivery: (state) => state.budgetDelivery,
  creativesStatuses: (state) => state.creativesStatuses,
  advertCreativeTags: (state) => state.advertCreativeTags,
  directDealsStatuses: (state) => state.directDealsStatuses,
  bannerDimensions: (state) => state.bannerDimensions,
  dimensions: (state) => state.dimensions,
  landingStatuses: (state) => state.landingStatuses,
  campaignStatuses: (state) => state.campaignStatuses,
  siteCategories: (state) => state.siteCategories,
  adunitFormats: (state) => state.adunitFormats,
  contentTypes: (state) => state.contentTypes,
  triggerMethods: (state) => state.triggerMethods,
  trafficTypesDict: (state) => state.trafficTypesDict,
  siteTypes: (state) => state.siteTypes,
  trafficTypes: (state) => state.trafficTypes,
  trafficSources: (state) => state.trafficSources,
  statuses: (state) => state.statuses,
  requestFormatTypes: (state) => state.requestFormatTypes,
  repeatTypes: (state) => state.repeatTypes,
  feedProtocols: (state) => state.feedProtocols,
  sspFeedProtocols: (state) => state.sspFeedProtocols,
  notifyTypes: (state) => state.notifyTypes,
  pricingModels: (state) => state.pricingModels,
  landingsDistribution: (state) => state.landingsDistribution,
  adUnitTypes: (state) => state.adUnitTypes,
  bidFormatTypes: (state) => state.bidFormatTypes,
  sspBidFormatTypes: (state) => state.sspBidFormatTypes,
  creativeRejectReasons: (state) => state.creativeRejectReasons,
  utcTimezones: () => utcTimezones,
  cpaStates: (state) => state.cpaStates,
  cpaSpotStates: (state) => state.cpaSpotStates,
  cpaRuleTypes: (state) => state.cpaRuleTypes,
  cpaConditions: (state) => state.cpaConditions,
  blacklistRuleStates: (state) => state.blacklistRuleStates,
  blacklistRuleTypes: (state) => state.blacklistRuleTypes,
  blacklistRuleConditions: (state) => state.blacklistRuleConditions,
  dealTypes: (state) => state.dealTypes,
  getLandingStatusByName: (state) => (name) => {
    const status = state.landingStatuses.find((status) => status.name === name);
    if (status) return status.id;
    return 0;
  },
  getCreativeAdTypes: (state) => {
    if (!state.adUnitTypes && state.adUnitTypes.length === 0) return [];
    return state.adUnitTypes.filter((el) => {
      return (
        el.id !== AD_TYPE_POPUNDER &&
        el.id !== AD_TYPE_INTERSTITIAL &&
        el.id !== AD_TYPE_LINK
      );
    });
  },
  getLandingRejectReasonById: (state) => (id) => {
    if (!id) return null;
    return state.landingRejectReasons.find(
      (creativeRejectReason) => id === creativeRejectReason.id
    );
  },
  getCreativeRejectReasonById: (state) => (id) => {
    if (!id) return null;
    return state.creativeRejectReasons.find(
      (creativeRejectReason) => id === creativeRejectReason.id
    );
  },
  creativeAdTags: (state) => {
    return state.adTags.filter((el) => {
      return el.forCreatives === 1;
    });
  },
  landingsAdTags: (state) => {
    return state.adTags.filter((el) => {
      return el.forLandings === 1;
    });
  },
  advCampaignDictionaries: (state) => {
    return {
      adunits: state.targetsAdunits,
      countries: state.countries,
      cookieTypes: state.cookieTypes,
      browsers: state.browsers,
      browserTypes: state.browserTypes,
      osTypes: state.osTypes,
      osNames: state.osNames,
      osVersions: state.osVersions,
      deviceModels: state.deviceModels,
      connectionTypes: state.connectionTypes,
      carriers: (() => {
        return state.carriers.map((carrier) => {
          carrier.name = carrier.shortName;
          return carrier;
        });
      })(),
      deviceTypes: state.deviceTypes,
      languages: state.languages,
      isps: state.isps,
      adFormats: state.adFormats,
      bannerDimensions: state.bannerDimensions,
      pricingModels: state.pricingModels,
      categories: state.siteCategories,
      trafficTypes: state.trafficTypes,
      autoRules: state.autoRules
    };
  },
  dictionaries: (state) => {
    return [
      {
        name: 'Ad formats',
        key: 'adFormat',
        list: state.adFormats
      },
      {
        name: 'Ad positions',
        key: 'adPositions',
        list: state.adPositions
      },
      {
        name: 'Banner dimensions',
        key: 'bannerDimensions',
        list: state.bannerDimensions
      },
      {
        name: 'Connection types',
        key: 'connectionType',
        list: state.connectionTypes
      },
      {
        name: 'Device types',
        key: 'deviceType',
        list: state.deviceTypes
      },
      {
        name: 'Exchange types',
        key: 'exchangeType',
        list: state.exchangeTypes
      },
      {
        name: 'Exchanges',
        key: 'exchange',
        list: state.exchanges
      },
      {
        name: 'Languages',
        key: 'language',
        list: state.languages
      },
      {
        name: 'Site labels',
        key: 'siteLabel',
        list: state.siteLabels
      },
      {
        name: 'Site categories',
        key: 'siteCategory',
        list: state.siteCategories
      },
      {
        name: 'Traffic types',
        key: 'trafficType',
        list: state.trafficTypes
      },
      {
        name: 'Metrics',
        key: 'metric',
        list: state.metrics
      },
      {
        name: 'Events',
        key: 'event',
        list: state.events
      }
    ].filter((el) => el.list.length !== 0);
  },
  getBidFormatTypeById: (state) => (id) => {
    if (!id) return [];
    return state.bidFormatTypes
      .filter((bidFormatType) => id === bidFormatType.id)
      .filter(Boolean);
  },
  getSSPBidFormatTypeById: (state) => (id) => {
    if (!id) return [];
    return state.sspBidFormatTypes
      .filter((sspBidFormatType) => id === sspBidFormatType.id)
      .filter(Boolean);
  },
  getContentTypeById: (state) => (id) => {
    if (!id) return [];
    return state.contentTypes.filter((type) => id === type.id).filter(Boolean);
  },
  getAdTagsByIds: (state) => (ids) => {
    if (!ids) return [];
    return state.adTags.filter((tag) => ids.includes(tag.id)).filter(Boolean);
  },
  getStatusById: (state) => (id) => {
    let status = 'paused';
    Object.keys(state.statuses).forEach((key) => {
      if (state.statuses[key] === id) status = key;
    });
    return status;
  },
  getAdunitTypeById: (state) => (id) => {
    if (!id) return [];
    return state.adUnitTypes.filter((type) => id === type.id).filter(Boolean);
  },
  getAdunitTypeByName: (state) => (name) => {
    if (!name) return [];
    return state.adUnitTypes
      .filter((type) => name === type.name)
      .filter(Boolean);
  },
  getFeedProtocolById: (state) => (id) => {
    if (!id) return [];
    return state.feedProtocols
      .filter((protocol) => id === protocol.id)
      .filter(Boolean);
  },
  getSSPFeedProtocolById: (state) => (id) => {
    if (!id) return [];
    return state.sspFeedProtocols
      .filter((protocol) => id === protocol.id)
      .filter(Boolean);
  },
  getNotifyTypeById: (state) => (id) => {
    if (!id) return [];
    return state.notifyTypes
      .filter((notifyType) => id === notifyType.id)
      .filter(Boolean);
  },
  getTrafficTypeDictById: (state) => (id) => {
    // Checking for null or undefined, 0 is a valid id
    if (id == null) {
      return [];
    }
    return state.trafficTypesDict.filter(
      (trafficType) => Boolean(trafficType) && id === trafficType.id
    );
  },
  getSiteTypeById: (state) => (id) => {
    if (!id) return [];
    return state.siteTypes
      .filter((siteType) => id === siteType.id)
      .filter(Boolean);
  },
  getSiteCategoriesById: (state) => (ids) => {
    if (!ids) return [];
    return state.siteCategories
      .filter((category) => ids.includes(category.id))
      .filter(Boolean);
  },
  getLandingDistributionById: (state) => (id) => {
    return state.landingsDistribution.find((algorithm) => algorithm.id === id);
  },
  getAdunitTypeId: (state) => (id) => {
    if (!id) return null;
    return state.adUnitTypes.find((item) => item.id === id);
  },
  getPricingModelById: (state) => (id) => {
    if (!id) return null;
    return state.pricingModels.find((item) => item.id === id);
  },
  getBannerDimensionById: (state) => (id) => {
    if (!id) return null;
    return state.bannerDimensions.find((item) => item.id === id);
  },
  getBudgetDeliveryById: (state) => (id) => {
    if (!id) return null;
    return state.budgetDelivery.find((item) => item.id === id);
  },
  getTrafficTypeById: (state) => (id) => {
    if (!id) return null;
    return state.trafficTypes.find((item) => item.id === id);
  },
  getRepeatTypeById: (state) => (id) => {
    if (!id) return null;
    return state.repeatTypes.find((item) => item.id === id);
  },
  getAdunitFormatById: (state, getters) => (id) => {
    if (!id) return null;
    const adTypes = Object.keys(state.adunitFormats);
    for (let i = 0; i < adTypes.length; i++) {
      const format = getters.adunitFormats[adTypes[i]].find(
        (item) => item.id === id
      );
      if (format) {
        return format;
      }
    }
    return null;
  }
};
