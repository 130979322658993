/* https://github.com/NxtChg/pieces/tree/master/js/vue/vs-notify */

export default function registerNotify(app) {
  let s = document.createElement('style');
  s.type = 'text/css';

  document.getElementsByTagName('head')[0].appendChild(s);

  s.innerHTML =
    '.vs-notify{ position:fixed !important; width:300px; z-index:9999; }' +
    '.vs-notify.alert{ padding: 0; }' +
    '.vs-notify > span > * { margin: 0.75rem 1.25rem; display: block; }' +
    '.ntf-left-enter-active, .ntf-left-leave-active, .ntf-right-enter-active, .ntf-right-leave-active, .ntf-top-enter-active, .ntf-top-leave-active,' +
    '.ntf-bottom-enter-active, .ntf-bottom-leave-active{ transition: all 0.3s; }' +
    '.ntf-left-enter,  .ntf-left-leave-to { opacity:0; transform:translateX(-300px); }' +
    '.ntf-right-enter, .ntf-right-leave-to{ opacity:0; transform:translateX(300px); }' +
    '.ntf-fade-enter-active, .ntf-fade-leave-active{ transition: opacity 0.5s; }' +
    '.ntf-fade-enter, .ntf-fade-leave-to{ opacity: 0; }' +
    '.ntf-top-enter,  .ntf-top-leave-to{ opacity:0; transform: translateY(-120px); }' +
    '.ntf-bottom-enter, .ntf-bottom-leave-to{ opacity:0; transform: translateY(120px); }' +
    '.notify-enter-active, .notify-leave-active { transition: all 0.5s ease; } .notify-enter-from, .notify-leave-to { opacity: 0; transform: translateX(30px); }';

  const VsNotify = {
    install(app) {
      const self = this;
      this.g = {};

      const $notify = function (
        group,
        { text, variant: type, time, dismissible, classList }
      ) {
        if (self.g[group])
          self.g[group](text, type, time, dismissible, classList);
      };

      app.config.globalProperties.$notify = $notify;
    }
  };

  app.use(VsNotify);

  app.component('VsNotify', {
    template: `<div :class="['vs-notify', group, additionalClasses]" :style="styles">
			<transition-group name="notify">
				<div :class="it.type" v-for="it in list" :key="it.id">
					<slot name="body" :class="it.type" :item="it" :close="function(){ end(it) }">
						<div @click.stop="end(it)" v-html="it.text"></div>
					</slot>
				</div>
			</transition-group>
		</div>`,

    props: {
      group: {
        type: String,
        default: ''
      },
      transition: {
        type: String,
        default: ''
      },
      position: { type: String, default: 'top right' },
      duration: { type: Number, default: 3000 },
      reverse: { type: Boolean, default: false }
    },

    data() {
      let reverse;
      let transition;
      if (this.position.indexOf('bottom') + 1) reverse = !this.reverse;

      if (!this.transition && this.position.indexOf('left') + 1)
        transition = 'ntf-left';
      if (!this.transition && this.position.indexOf('right') + 1)
        transition = 'ntf-right';

      return {
        dir: reverse,
        trans: transition,
        list: [],
        additionalClasses: ''
      };
    },

    created() {
      let ids = 1;

      VsNotify.g[this.group] = (
        text,
        type,
        time,
        dismissible,
        classList = ''
      ) => {
        if (text === undefined) {
          this.end();
          return;
        }
        let it = {
          id: ids++,
          text: text,
          type: type ? type : 'info',
          dismissible: dismissible ? dismissible : true
        };

        time = time || this.duration;
        this.additionalClasses = classList;
        if (time > 0) {
          it.timer = setTimeout(() => {
            this.end(it);
          }, time);
        }

        this.dir ? this.list.push(it) : this.list.unshift(it);
      };
    },

    computed: {
      styles() {
        let s = {},
          pa = this.position.split(' ');

        for (let i = 0; i < pa.length; i++) {
          if (pa[i] == 'center') {
            s.left = s.right = 0;
            s.margin = 'auto';
          } else if (pa[i] != '') s[pa[i]] = 0;
        }

        return s;
      }
    },

    methods: {
      find(id) {
        for (let i = 0; i < this.list.length; i++)
          if (this.list[i].id == id) return i;
        return -1;
      },

      endNo(n) {
        if (n + 1) {
          clearTimeout(this.list[n].timer);
          this.list.splice(n, 1);
        }
      },

      end(it) {
        if (it === undefined) {
          while (this.list.length) this.endNo(0);
          return;
        } // kill all

        this.endNo(this.find(it.id));
      }
    }
  });
}
