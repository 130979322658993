import date from 'date-fns';
import numeral from 'numeral';

const getCurrencySymbol = (code) => {
  switch (code) {
    case 'USD':
      return '$';
    default:
      return '$';
  }
};

export const formatWideDecimal = (value, extraWide) => {
  const number = Number(value);
  if (isNaN(number)) return value;
  const absValue = Math.abs(number);
  let format = absValue % 1 === 0 ? '0' : '0.00';
  if (extraWide && absValue % 1 !== 0) format = '0.0000';
  if (absValue < 1) return numeral(number).format(format);
  if (absValue >= 1 && absValue <= 99999) return numeral(number).format(format);
  if (absValue > 99999) return numeral(number).format('0.0a').toUpperCase();
};
export const formatWideInt = (value) => {
  const number = Number(value);
  if (isNaN(number)) return;
  return numeral(number).format('10,000').toUpperCase();
};
export const formatIntToSymbols = (value) => {
  const number = Number(value);
  if (isNaN(number)) return;
  return numeral(number).format('0[.]0a').toUpperCase();
};

export const formatFileSize = (size) => {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB';
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  }
  return size.toString() + ' B';
};

export default function registerFilters(app) {
  // Filters are converted to reusable functions
  const filters = {
    formatMoneyObjToString(val) {
      return val ? `${getCurrencySymbol(val.currency.code)}${val.amount}` : '-';
    },
    capitalize(value) {
      if (!value) return '';
      return value.toString().charAt(0).toUpperCase() + value.slice(1);
    },
    formatWideDecimal,
    formatIntToSymbols,
    formatFileSize,
    formatWideInt,
    upperCase(val) {
      if (typeof val === 'string' || val instanceof String) {
        return val.toUpperCase();
      }
      return val;
    },
    formatDate(value) {
      return value ? date.format(value, 'DD.MM.YYYY') : '';
    }
  };

  // Registering all filters globally using app.config.globalProperties
  app.config.globalProperties.$filters = filters;

  // Registering as reusable functions so that you can directly use them in your components
  Object.keys(filters).forEach((key) => {
    app.config.globalProperties[`$${key}`] = filters[key];
  });
}
