<template>
  <div></div>
</template>

<script>
import { useToastController } from 'bootstrap-vue-next';

export default {
  name: 'ToastController',
  setup() {
    const toastController = useToastController();
    return {
      showToast: toastController.show
    };
  }
};
</script>
