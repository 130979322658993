import { getCurrentInstance } from 'vue';

import useScreen from '@/composables/useScreen';

export default function useScreenSetup() {
  const { screenState } = useScreen();
  const app = getCurrentInstance();

  if (app) {
    app.appContext.config.globalProperties.$screenState = screenState;
  }
}
