<template>
  <div :class="{ 'adblock-enabled': isAdBlockEnabled }">
    <ToastController ref="toastController" />
    <BModalOrchestrator />
    <BToastOrchestrator />
    <VsNotify group="alert" v-if="!isAdBlockEnabled">
      <template #body="props">
        <b-alert
          :class="props.class + ' mb-0'"
          :model-value="true"
          :variant="props.item.type"
          @close="props.close(props.item)"
          :dismissible="true"
        >
          {{ props.item.text }}
        </b-alert>
      </template>
    </VsNotify>
    <component :is="layout" v-if="!isAdBlockEnabled">
      <router-view v-slot="{ Component }">
        <transition
          name="fade-transform"
          mode="out-in"
          @after-enter="afterEnter"
          @before-leave="beforeLeave"
        >
          <component :is="Component" :key="transitionKey" />
        </transition>
      </router-view>
    </component>
    <div v-else class="adblock-enabled-message text-center">
      Please disable the ad blocker to use our platform.
      <button @click="reloadPage" class="btn btn-sm btn-primary mt-3">
        Refresh page
      </button>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useRoleWatcher } from '@/composables/useRoleWatcher';
import { useHead } from '@/hook/useHead';
import useScreenSetup from '@/hook/useScreenSetup';
import initializeApp from '@/initializeApp';

import checkAdBlock from './utils/checkAdBlock';
import ToastController from '@/components/utils/ToastController.vue';
const DefaultLayout = defineAsyncComponent(
  () => import('./layouts/default.vue')
);
const EmptyLayout = defineAsyncComponent(() => import('./layouts/empty.vue'));
import { BModalOrchestrator, BToastOrchestrator } from 'bootstrap-vue-next';
import { useTheme } from '@/composables/useTheme';

export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    BModalOrchestrator,
    BToastOrchestrator,
    ToastController
  },

  data() {
    return {
      isAdBlockEnabled: false
    };
  },

  setup() {
    useTheme();
    useHead();
    useRoleWatcher();

    useScreenSetup();

    const store = useStore();
    const router = useRouter();
    const instance = getCurrentInstance();

    onMounted(() => {
      initializeApp({
        store,
        router,
        userSettings: instance.proxy.userSettings,
        config: instance.proxy.$config
      });
    });
  },

  computed: {
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
    transitionKey() {
      return this.$route.meta.noParamsTransition
        ? this.$route.name
        : this.$route.fullPath;
    }
  },

  methods: {
    afterEnter() {
      document.querySelector('.app-content') &&
        document.querySelector('.app-content').classList.remove('wrapper');
      this.$bus.$emit('page-after-enter');
    },
    beforeLeave() {
      document.querySelector('.app-content') &&
        document.querySelector('.app-content').classList.add('wrapper');
    },
    reloadPage() {
      window.location.reload();
    }
  },

  created() {
    this.isAdBlockEnabled = checkAdBlock.isDetected();
  }
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  min-height: 100%;

  &.adblock-enabled {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.adblock-enabled-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
