import { cloneDeep } from 'lodash';
import Vue from 'vue';

const SETTINGS_ROUTE = '/user-settings';

export default class UserSettings {
  constructor() {
    this.userId = 0;
    this.statsColumns = {};
    this.statsTableColumnsOrder = {};
    this.statsColumnsPresets = [];
    this.statsTableHideByZeroMetrics = {};
    this.statsSegments = [];
    this.statsTableConfig = {
      striped: false,
      borderless: false,
      minimizeCols: false,
      cellSize: 1
    };
  }

  init() {
    return new Promise((resolve) => {
      this.getSettings().then((settings) => {
        settings && this.setSettings(settings);
        resolve();
      });
    });
  }

  getSettings() {
    return Vue.api.auth.get(SETTINGS_ROUTE).then(
      (res) => res,
      (e) => {
        console.log('US Error: get settings');
        e.response.data &&
          console.log(`US Error: - ${e.response.data.error.message}`);
        return null;
      }
    );
  }

  setSettings(settings) {
    const s = cloneDeep(settings);
    this.userId = s.id;
    this.statsColumns = s.statsColumns != null ? s.statsColumns : {};
    this.statsTableColumnsOrder =
      s.statsTableColumnsOrder != null ? s.statsTableColumnsOrder : {};
    this.statsColumnsPresets =
      s.statsColumnsPresets != null ? s.statsColumnsPresets : [];
    this.statsTableHideByZeroMetrics =
      s.statsTableHideByZeroMetrics != null
        ? s.statsTableHideByZeroMetrics
        : {};
    this.statsSegments = s.statsSegments != null ? s.statsSegments : [];

    if (s.statsTableConfig) {
      if (s.statsTableConfig.striped)
        this.statsTableConfig.striped = s.statsTableConfig.striped;
      if (s.statsTableConfig.borderless)
        this.statsTableConfig.borderless = s.statsTableConfig.borderless;
      if (s.statsTableConfig.minimizeCols)
        this.statsTableConfig.minimizeCols = s.statsTableConfig.minimizeCols;
      if (
        s.statsTableConfig.hasOwnProperty('cellSize') &&
        Number.isInteger(s.statsTableConfig.cellSize) &&
        s.statsTableConfig.cellSize >= 0 &&
        s.statsTableConfig.cellSize <= 3
      )
        this.statsTableConfig.cellSize = s.statsTableConfig.cellSize;
    }
  }

  updateSettings() {
    const settings = {
      statsColumns: cloneDeep(this.statsColumns)
    };

    Vue.api.auth.put(SETTINGS_ROUTE, settings).then(
      (res) => {
        this.setSettings(res);
      },
      (e) => {
        console.log('US Error: update settings');
        e.response.data &&
          console.log(`US Error: - ${e.response.data.error.message}`);
        return null;
      }
    );
  }

  updateSettingsFields(fields) {
    Vue.api.auth.put(SETTINGS_ROUTE, fields).then(
      (res) => {
        this.setSettings(res);
      },
      (e) => {
        console.log('US Error: update settings');
        e.response.data &&
          console.log(`US Error: - ${e.response.data.error.message}`);
        return null;
      }
    );
  }

  getStatsTableConfig() {
    return this.statsTableConfig;
  }

  getStatsColumns(id) {
    return this.statsColumns[id] || null;
  }

  setStatsColumns(id, columns) {
    this.statsColumns[id] = cloneDeep(columns);
    this.updateSettingsFields({ statsColumns: this.statsColumns });
  }

  getStatsTableColumnsOrder(id) {
    return this.statsTableColumnsOrder[id] || null;
  }

  setStatsTableColumnsOrder(id, columns) {
    this.statsTableColumnsOrder[id] = cloneDeep(columns);
    this.updateSettingsFields({
      statsTableColumnsOrder: this.statsTableColumnsOrder
    });
  }

  getStatsTableHideByZeroMetrics(id) {
    return this.statsTableHideByZeroMetrics[id] || [];
  }

  setHideByZeroMetrics(id, metrics) {
    this.statsTableHideByZeroMetrics[id] = cloneDeep(metrics);
    this.updateSettingsFields({
      statsTableHideByZeroMetrics: this.statsTableHideByZeroMetrics
    });
  }

  getStatsColumnsPresets() {
    return this.statsColumnsPresets || [];
  }

  addStatsColumnsPreset(preset) {
    this.statsColumnsPresets.push(preset);
    this.updateSettingsFields({
      statsColumnsPresets: this.statsColumnsPresets
    });
  }

  editStatsColumnsPreset(presetId, preset) {
    const presetIndex = this.statsColumnsPresets.findIndex(
      (p) => p.id === presetId
    );
    if (presetIndex === -1) return;

    this.statsColumnsPresets[presetIndex] = preset;
    this.updateSettingsFields({
      statsColumnsPresets: this.statsColumnsPresets
    });
  }

  deleteStatsColumnsPreset(presetId) {
    const presetIndex = this.statsColumnsPresets.findIndex(
      (p) => p.id === presetId
    );
    if (presetIndex === -1) return;

    this.statsColumnsPresets.splice(presetIndex, 1);
    this.updateSettingsFields({
      statsColumnsPresets: this.statsColumnsPresets
    });
  }

  // getStatsSegmentsList() {
  // 	return this.statsSegments;
  // }

  // getStatsSegment(id) {
  // 	return this.statsSegments.find(s => s.id === id);
  // }

  // addStatsSegment(segment) {
  // 	const duplicateName = this.statsSegments.some(s => s.name === segment.name);
  // 	if (duplicateName) return false;

  // 	const newSegment = {
  // 		id: Date.now(),
  // 		name: segment.name.trim(),
  // 		queryId: segment.queryId,
  // 		withDateRange: segment.withDateRange,
  // 		roleId: segment.roleId,
  // 	};
  // 	this.statsSegments.unshift(newSegment);
  // 	this.updateSettings();
  // 	return newSegment;
  // }

  // editStatsSegment(segment) {
  // 	const editIndex = this.statsSegments.findIndex(s => s.id === segment.id)
  // 	if (editIndex === -1) return false;

  // 	this.statsSegments[editIndex] = segment;
  // 	this.updateSettings();
  // 	return true;
  // }

  // deleteStatsSegment(segment) {
  // 	const deleteIndex = this.statsSegments.findIndex(s => s.id === segment.id)
  // 	if (deleteIndex === -1) return false;

  // 	this.statsSegments.splice(deleteIndex, 1);
  // 	this.updateSettings();
  // 	return true;
  // }
}
