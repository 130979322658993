const requireConfig = () => {
  const config = require(`./${process.env.PLATFORM}/index.js`);

  return config.default;
};

export default Object.assign(
  {
    statuses: new Map([
      [0, 'active'],
      [1, 'pending'],
      [2, 'closed'],
      [3, 'rejected']
    ])
  },
  requireConfig()
);
