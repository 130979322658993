import 'nprogress/nprogress.css'; // Import base styles for the NProgress bar, necessary for proper styling when using "@vueuse/integrations/useNProgress"
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

import * as Sentry from '@sentry/vue';
import { configureCompat } from '@vue/compat';
import { createBootstrap } from 'bootstrap-vue-next';
import { createApp } from 'vue';
import UserSettings from '@/utils/userSettings.js';

import config from './../config/index';
import createApi from './api/index.js';
import App from './App.vue';
import i18n from './i18n';
import registerPlugins from './plugins/index';
import router from './router/index.js';
import store from './store';
import * as auth from './utils/auth.js';
import registerComponents from './utils/components';
import registerDirectives from './utils/directives';
import registerFilters from './utils/filters';
import registerErrorMessages from './utils/form.js';
import(`./assets/scss/${process.env.PLATFORM}.scss`);
// eslint-disable-next-line
console.info({
  APP_VER: process.env.APP_VER,
  SENTRY_URL: process.env.SENTRY_URL,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
  SENTRY_TRACES_SAMPLE_RATE: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  SENTRY_DEBUG: process.env.SENTRY_DEBUG,
  PLATFORM: process.env.PLATFORM
});

// Configure compatibility mode with all options enabled
configureCompat({
  MODE: 3,
  //
  // // Global API
  // GLOBAL_MOUNT: true,
  // GLOBAL_EXTEND: true, // re-enable the use of Vue.extend for defining components
  // GLOBAL_PROTOTYPE: true, // Enables the Use of Vue.prototype
  // GLOBAL_SET: true,
  // GLOBAL_DELETE: true,
  // GLOBAL_OBSERVABLE: true,
  //
  // // Instance APIs
  INSTANCE_SET: true,
  // INSTANCE_DELETE: true,
  // INSTANCE_DESTROY: true,
  INSTANCE_EVENT_EMITTER: true, // Enables support for `$on`, `$once`, `$off`
  // INSTANCE_EVENT_HOOKS: true,
  // INSTANCE_CHILDREN: true,
  // INSTANCE_LISTENERS: true,
  // INSTANCE_ATTRS_CLASS_STYLE: true,
  //
  // // Options
  // OPTIONS_DATA_MERGE: true,
  // OPTIONS_BEFORE_DESTROY: true,
  // OPTIONS_DESTROYED: true,
  //
  // // Watch
  // WATCH_ARRAY: true,
  //
  // // Other
  // PROPS_DEFAULT_THIS: true,
  // V_ON_NATIVE_MODIFIER: true,
  // CUSTOM_DIR: true,
  // ATTR_FALSE_VALUE: true,
  // ATTR_ENUMERATED_COERCION: true,
  // TRANSITION_GROUP_ROOT: true,
  //
  // // Enable FILTERS compatibility
  // FILTERS: true,
  //
  // // Other utilities
  GLOBAL_PRIVATE_UTIL: true
});

const app = createApp(App);

app.use(createBootstrap());

// Equivalent to Vue.prototype.$config, accessible via this.$config
app.config.globalProperties.$config = config;

if (process.env.SENTRY_URL) {
  Sentry.init({
    app,
    dsn: process.env.SENTRY_URL,
    release: `${process.env.PLATFORM}@${process.env.APP_VER}`,
    attachProps: true,
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE) || 0,
    integrations: [Sentry.browserTracingIntegration({ router })],
    environment: process.env.SENTRY_ENVIRONMENT,
    debug: process.env.SENTRY_DEBUG
  });
}

registerPlugins(app);
registerFilters(app);
registerComponents(app);
registerDirectives(app);
registerErrorMessages(app);

// Create global UserSettings instance, equivalent to Vue.prototype.userSettings, accessible via this.userSettings
app.config.globalProperties.userSettings = new UserSettings();

createApi(
  [
    {
      name: 'auth',
      baseUrl: `${process.env.AUTH_API_HOST}`,
      authRequired: true,
      lang: true
    },
    {
      name: 'bounty',
      baseUrl: `${process.env.BOUNTY_API_HOST}`,
      authRequired: true,
      lang: true
    },
    {
      name: 'adnet',
      baseUrl: `${process.env.ADNET_API_HOST}`,
      authRequired: true,
      lang: true,
      role: true
    },
    {
      name: 'core',
      baseUrl: `${process.env.CORE_API_HOST}`
    }
  ],
  {
    token: auth.readToken(),
    refreshToken: auth.readRefreshToken(),
    app
  }
);

app.use(store);

app.use(i18n);
app.use(router);
// TODO: do we nee to export store here or not in Vue3?
export { store };
app.mount('#app');
