<template>
  <div class="vue-avatar" :style="computedStyle" aria-hidden="true">
    <span>{{ userInitial }}</span>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    username: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 50
    },
    src: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      imgError: false,
      backgroundColors: [
        '#F44336',
        '#FF4081',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#2196F3',
        '#03A9F4',
        '#00BCD4',
        '#009688',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFC107',
        '#FF9800',
        '#FF5722',
        '#795548',
        '#9E9E9E',
        '#607D8B'
      ]
    };
  },

  computed: {
    computedStyle() {
      const baseStyle = {
        display: 'flex',
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: '50%',
        lineHeight: `${this.size}px`,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        userSelect: 'none'
      };

      return {
        ...baseStyle,
        backgroundColor: this.randomBackgroundColor(this.username.length),
        fontSize: `${Math.floor(this.size / 2.5)}px`,
        color: this.lightenColor(
          this.randomBackgroundColor(this.username.length),
          80
        )
      };
    },

    userInitial() {
      return this.getInitials(this.username);
    }
  },

  methods: {
    randomBackgroundColor(seed) {
      return this.backgroundColors[seed % this.backgroundColors.length];
    },

    getInitials(username) {
      return username
        .split(/[ -]/)
        .map((part) => part.charAt(0).toUpperCase())
        .slice(0, 3)
        .join('');
    },

    lightenColor(hex, amt) {
      let usePound = false;

      if (hex[0] === '#') {
        hex = hex.slice(1);
        usePound = true;
      }

      let num = parseInt(hex, 16);
      let r = Math.min(255, Math.max(0, (num >> 16) + amt));
      let b = Math.min(255, Math.max(0, ((num >> 8) & 0x00ff) + amt));
      let g = Math.min(255, Math.max(0, (num & 0x0000ff) + amt));

      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    }
  }
};
</script>

<style scoped>
.vue-avatar {
  overflow: hidden;
  position: relative;
}

.vue-avatar__image {
  display: none;
}
</style>
