import { defineAsyncComponent } from 'vue';

const SyndicationDomains = defineAsyncComponent(
  () => import('@/views/Publisher/SyndicationDomains/List.vue')
);
const SyndicationDomainForm = defineAsyncComponent(
  () => import('@/views/Publisher/SyndicationDomains/Form.vue')
);

const routes = [
  {
    path: '/syndication-domains',
    component: SyndicationDomains,
    name: 'syndication-domains',
    meta: {
      roles: ['publisher'],
      title: 'Syndication domains',
      icon: 'menu-syndication-domains',
      alias: 'sd'
    }
  },
  {
    path: '/syndication-domains/add',
    component: SyndicationDomainForm,
    name: 'syndication-domain-form',
    hidden: true,
    meta: {
      roles: ['publisher'],
      title: 'Add syndication domain',
      icon: 'menu-sites',
      alias: 'sd:a'
    }
  },
  {
    path: '/syndication-domains/:id',
    component: SyndicationDomainForm,
    name: 'syndication-domain-edit',
    hidden: true,
    meta: {
      roles: ['publisher'],
      title: 'Edit syndication domain',
      icon: 'menu-sites'
    }
  }
];

export default routes;
