/* eslint-env node */
export default {
  hostName: 'HProfits.com',
  roles: new Map([
    [3, 'publisher'],
    [4, 'advertiser'],
    [5, 'admin'],
    [9, 'advert-admin'],
    [10, 'ad-exchange'],
    [11, 'manager']
  ]),
  rolesIds: {
    publisher: 3,
    advertiser: 4,
    admin: 5,
    'advert-admin': 9,
    'ad-exchange': 10,
    manager: 11
  },
  excludedRoles: [5],
  excludedRolesForUser: [5, 8, 9, 10, 11],
  chart: {
    colors: [
      '#ffb007',
      '#3783f7',
      '#559ec7',
      '#45b774',
      '#5160b7',
      '#64E572',
      '#FF9655',
      '#FFF263',
      '#6AF9C4'
    ],
    xAxis: {
      tickColor: '#DFE1ED',
      labels: {
        style: {
          color: '#6B7376'
        }
      }
    },
    yAxis: {
      gridLineColor: '#DFE1ED',
      labels: {
        style: {
          color: '#6B7376'
        }
      }
    },
    plotOptions: {
      series: {
        shadow: {
          color: '#FFFAFA'
        },
        marker: {
          fillColor: '#ffffff'
        }
      },
      map: {
        nullColor: '#dfe1ed'
      }
    },
    colorAxis: {
      min: 0,
      minColor: '#fdf9f0',
      maxColor: '#FFB007'
    },
    series: [
      {
        states: {
          hover: {
            color: '#e73a2f'
          }
        }
      }
    ]
  },
  invoice: {
    bodyLineColor: '#21C063',
    hLineColor: '#DFE1ED',
    vLineColor: '#DFE1ED',
    tablePaymentFillColor: '#d4edfb',
    stylesStatusColor: '#21C063',
    paymentMethodColor: '#14557b'
  },
  datepicker: {
    datePickerTintColor: '#3783f7',
    base: {
      wrapper: {
        backgroundColor: '#fff',
        border: '1px solid #DFE1ED',
        borderRadius: '3px',
        boxShadow: 'none'
      },
      dayCellNotInMonth: {
        opacity: 0,
        pointerEvent: 'none',
        display: 'none'
      },
      weekdays: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#6B7376'
      }
    },
    disabled: {
      contentStyle: {
        color: '#242527',
        backgroundColor: 'rgba(22,64,97,0.2)'
      }
    }
  },
  defaultEditorSettings: {
    editorSettings: {
      modules: {
        imageDrop: true,
        imageResize: {}
      }
    },
    customToolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      // ['link', 'image'],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ fullscreen: 'click' }]
    ]
  },
  taskIcons: new Map([
    ['linkedin--join', 'follow'],
    ['linkedin--share and like', 'repost'],
    ['linkedin--post', 'post'],
    ['facebook--like', 'like'],
    ['facebook--share', 'repost'],
    ['facebook--comment', 'comment-o'],
    ['facebook--post', 'post'],
    ['ig--post', 'post'],
    ['ig--comment', 'comment-o'],
    ['ig--like', 'like'],
    ['ig--follow', 'follow'],
    ['medium--follow channel', 'follow'],
    ['medium--claps', 'clap'],
    ['medium--comments', 'comment-o'],
    ['telegram--join', 'follow'],
    ['telegram--avatar', 'user'],
    ['telegram--text in name', 'post'],
    ['telegram--weekly stakes', 'comment-o'],
    ['tw--like', 'like'],
    ['tw--retweet', 'repost'],
    ['tw--tweet', 'twitter-o'],
    ['tw--comment', 'comment-o'],
    ['yt--like', 'like'],
    ['yt--video', 'play'],
    ['yt--follow', 'follow'],
    ['yt--comment', 'comment-o'],
    ['steemit--join', 'follow'],
    ['steemit--upvote', 'like'],
    ['steemit--resteem', 'repost'],
    ['steemit--post', 'post'],
    ['steemit--comment', 'comment-o'],
    ['reddit--subscribe', 'subscribe'],
    ['reddit--follow', 'follow'],
    ['reddit--post', 'post'],
    ['reddit--comment', 'comment-o'],
    ['translations--whitepaper', 'whitepaper'],
    ['translations--main website translate', 'site'],
    ['translations--ico website translate', 'site'],
    ['translations--bitcointalk ann thread', 'thread'],
    ['translations--bounty thread', 'thread'],
    ['translations--one pager', 'whitepaper'],
    ['translations--lightpaper', 'whitepaper'],
    ['translations--moderation', 'moderation']
  ]),
  verificationFile: 'hprofits.txt',
  contacts: {
    telegram: 'hprofits',
    skype: 'skype:live:info_1017176?chat',
    skypeName: 'HProfits Support',
    email: 'info@hprofits.com'
  }
};
