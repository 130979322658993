<template>
  <b-form-input v-bind="$attrs" v-model.number="val" />
</template>

<script>
export default {
  // eslint-disable-next-line
  props: ['modelValue'],

  data() {
    return {
      val: this.modelValue
    };
  },

  watch: {
    modelValue(value) {
      this.val = value === '' ? (value = null) : value;
    },
    val(val) {
      this.$emit('update:modelValue', val === '' ? (val = null) : val);
    }
  }
};
</script>
