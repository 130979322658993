import axios from 'axios';
import Vue from 'vue';

import * as config from '@/../config/index.js';
import * as auth from '@/utils/auth.js';

export default class Api {
  /**
   * @param {Object} options={}
   */
  constructor(options = {}) {
    this.client =
      options.client ||
      axios.create({
        baseURL: options.baseUrl,
        timeout: 300000
      });

    if (options.lang) {
      this.client.defaults.headers.common['X-LANG'] = process.env.LANGUAGE;
    }

    this.token = options.token;
    this.refreshToken = options.refreshToken;
    this.options = {
      ...options
    };
    this.disguiseRequest = {
      token: null,
      roleId: null,
      isDisguiseRequest: false
    };

    this.client.interceptors.request.use(
      (config) => {
        window.localStorage.removeItem('login-form');

        // For `/stats/grouped`, `/stats/ranges` and `stats/fraud-tags`, the backend requires the `q` parameter as a JSON string.
        // Newer Axios versions serialize nested objects in `params` into query strings by default.
        // Example:
        //   Input: { q: { key: 'value', nested: { subKey: 'subValue' } } }
        //   Default latest Axios Result: q[key]=value&q[nested][subKey]=subValue
        //
        // The backend expects JSON-encoded `q`:
        //   Required Result: q={"key":"value","nested":{"subKey":"subValue"}}
        //
        // Fix: Use `JSON.stringify` to ensure the `q` parameter is sent as a JSON string.
        // Avoid double JSON.stringify by stringifying only if `q` is an object
        if (
          ['/stats/fraud-tags', '/stats/grouped', '/stats/ranges'].includes(
            config.url
          ) &&
          typeof config.params?.q == 'object'
        ) {
          config.params.q = JSON.stringify(config.params.q);
        }
        if (!this.token) return config;
        if (this.options.authRequired) {
          const newConfig = {
            ...config
          };

          if (this.disguiseRequest.isDisguiseRequest) {
            newConfig.headers.Authorization = `Bearer ${this.disguiseRequest.token}`;
            newConfig.headers['X-ACTIVE-ROLE-ID'] = this.disguiseRequest.roleId;
            this.disguiseRequest.isDisguiseRequest = false;
          } else {
            newConfig.headers.Authorization = `Bearer ${this.token}`;
          }

          return newConfig;
        }
        return config;
      },
      (e) => Promise.reject(e)
    );

    this.client.interceptors.response.use(
      (r) => r,
      async (error) => {
        if (error instanceof axios.Cancel) throw error;

        if (error.response && error.config.url.includes('auth/refresh-token')) {
          window.localStorage.setItem('login-form', 'true');
          Vue.api.logout();
          return;
        }

        if (!this.refreshToken && error.response?.status === 401) {
          Vue.api.logout();
          return;
        }

        if (
          !this.refreshToken ||
          error.response === undefined ||
          error.response.status !== 401 ||
          error.config.retry
        ) {
          throw error;
        }

        if (!Vue.api.refreshRequest) {
          const payload = {
            refreshToken: this.refreshToken,
            fp: auth.getFp(auth.readUser())
          };
          const failedRefreshEmail = auth.readUser();
          Vue.api.refreshRequest = this.client
            .put(`${process.env.AUTH_API_HOST}/auth/refresh-token`, payload)
            .then((response) => {
              this.token = response.data.accessToken;
              this.refreshToken = response.data.refreshToken;
              if (auth.readUser()) {
                Vue.api.updateCredentials({
                  token: this.token,
                  refreshToken: this.refreshToken,
                  email: auth.readUser()
                });
              }
              newRequest.headers.Authorization = `Bearer ${this.token}`;
              Vue.api.queue = Vue.api.queue.map((callback) => {
                if (typeof callback === 'function') {
                  callback(this.token);
                }
              });
              Vue.api.refreshRequest = null;
            })
            .catch(() => {
              window.location = '/login?' + 'e=' + failedRefreshEmail;
            });
        }

        const newRequest = {
          ...error.config,
          retry: true
        };

        if (Vue.api.refreshRequest) {
          return new Promise((resolve) => {
            Vue.api.queue.push((accessToken) => {
              newRequest.headers.Authorization = 'Bearer ' + accessToken;
              resolve(this.client(newRequest));
            });
          });
        }

        if (auth.readUser()) {
          Vue.api.updateCredentials({
            token: this.token,
            refreshToken: this.refreshToken,
            email: auth.readUser()
          });
        }

        return this.client(newRequest);
      }
    );
  }

  async login({ login, password }) {
    const { data } = await this.client.post(
      `${process.env.AUTH_API_HOST}/auth/login`,
      {
        login,
        password
      }
    );
    this.token = data.accessToken;
    this.refreshToken = data.refreshToken;

    return { token: data.accessToken, refreshToken: data.refreshToken };
  }

  logout() {
    window.localStorage.removeItem('entryUrl');
    this.token = null;
    this.refreshToken = null;
  }

  async request(method, url, params = {}, userData = null) {
    if (this.options.role) {
      this.client.defaults.headers.common['X-ACTIVE-ROLE-ID'] =
        config.default.rolesIds[auth.readActiveRole()];
    }

    if (userData !== null) {
      this.disguiseRequest = userData;
      this.disguiseRequest.isDisguiseRequest = true;
    }

    return this.client[method](url, params)
      .then(({ data }) => data)
      .catch((e) => Promise.reject(e));
  }

  async get(url, params = {}) {
    return this.request('get', url, params);
  }

  async post(url, params = {}) {
    return this.request('post', url, params);
  }

  async postByUser(url, params = {}, userData = {}) {
    return this.request('post', url, params, userData);
  }

  async put(url, params = {}) {
    return this.request('put', url, params);
  }

  async delete(url, params = {}) {
    return this.request('delete', url, params);
  }
}
