import { ref, onMounted, onBeforeUnmount } from 'vue';

export function useTheme() {
  const setTheme = (newTheme) => {
    theme.value = newTheme;
    window.localStorage.setItem('theme', newTheme);

    if (newTheme === 'auto') {
      handleSystemTheme();
    } else {
      applyTheme(newTheme);
      removeSystemThemeListener();
    }
  };

  const getTheme = () => {
    return window.localStorage.getItem('theme') || 'light';
  };

  const theme = ref(getTheme());
  let systemThemeListener = null;

  const handleSystemTheme = () => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    applyTheme(prefersDark.matches ? 'dark' : 'light');

    if (!systemThemeListener) {
      systemThemeListener = (e) => {
        applyTheme(e.matches ? 'dark' : 'light');
      };
      prefersDark.addEventListener('change', systemThemeListener);
    }
  };

  const applyTheme = (theme) => {
    document.documentElement.setAttribute('data-bs-theme', theme);
  };

  const removeSystemThemeListener = () => {
    if (systemThemeListener) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      prefersDark.removeEventListener('change', systemThemeListener);
      systemThemeListener = null;
    }
  };

  onMounted(() => {
    if (theme.value === 'auto') {
      handleSystemTheme();
    } else {
      applyTheme(theme.value);
    }
  });

  onBeforeUnmount(() => {
    removeSystemThemeListener();
  });

  return {
    theme,
    setTheme
  };
}
