<template>
  <div class="position-relative input--has-icon" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    left: { type: Boolean, default: true },
    right: { type: Boolean, default: false }
  },

  computed: {
    classes() {
      return {
        'has-icon-left': this.left,
        'has-icon-right': this.right
      };
    }
  }
};
</script>

<style lang="scss">
.input--has-icon {
  .icon {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    width: 35px;
    text-align: center;
  }

  input {
    padding-left: 35px;
  }
}
</style>
