import qs from 'qs';
import { createRouter, createWebHistory } from 'vue-router';

import adnetAdminRoutes from './routes/adnet-admin.js';
import adunitRoutes from './routes/adunit';
import errorRoutes from './routes/error.js';
import profileRoutes from './routes/profile.js';
import siteRoutes from './routes/site.js';
import syndicationDomainsRoutes from './routes/syndication-domains';
import targetRoutes from './routes/targets';

const Postbacks = () => import('@/views/Postbacks/index.vue');
const Campaigns = () => import('@/views/Campaign/index.vue');
const CampaignAdd = () => import('@/views/Campaign/CampaignAdd.vue');
const RtbCampaigns = () => import('@/views/Campaign/RtbCampaign/Campaigns.vue');
const RtbCampaignForm = () =>
  import('@/views/Campaign/RtbCampaign/CampaignAdd.vue');
const Creatives = () => import('@/views/Advertiser/Creatives/List.vue');
const Users = () => import('@/views/Campaign/Admin/Users.vue');
const Dashboard = () => import('@/views/Dashboard/index.vue');
const Stats = () => import('@/views/Stats/index.vue');
const Contacts = () => import('@/views/Contacts/index.vue');
const Privacy = () => import('@/views/Privacy/index.vue');
const LandingGroupsList = () => import('@/views/Advertiser/Landings/List.vue');
const Payments = () => import('@/views/Payments/index.vue');
const PaymentsPubs = () =>
  import('@/views/Payments/Publisher/pubPaymentsAdmin.vue');
const PaymentsSsp = () => import('@/views/Payments/Admin/sspPayments.vue');
const Marketplace = () => import('@/views/Advertiser/Marketplace/index.vue');
const ArbitrageDashboard = () =>
  import('@/views/Dashboard/Admin/ArbitrageDashboard.vue');

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    hidden: true,
    meta: {
      title: 'dashboard',
      icon: 'menu-home',
      alias: 'db'
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      roles: ['advertiser', 'publisher', 'advert-admin', 'manager'],
      title: 'dashboard',
      icon: 'menu-home',
      alias: 'db'
    }
  },
  {
    path: '/arbitrage-dashboard',
    component: ArbitrageDashboard,
    hidden: true,
    name: 'arbitrage-dashboard',
    meta: {
      title: 'Arbitrage dashboard',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/campaigns',
    component: Campaigns,
    name: 'campaigns',
    meta: {
      roles: ['advertiser', 'advert-admin', 'manager'],
      title: 'campaigns',
      icon: 'menu-campaign',
      alias: 'cm'
    }
  },
  {
    path: '/rtb-campaigns',
    component: RtbCampaigns,
    name: 'rtb-campaigns',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'RTB campaigns',
      icon: 'menu-rtb-campaign',
      alias: 'rcm'
    }
  },
  {
    path: '/marketplace',
    component: Marketplace,
    name: 'marketplace',
    meta: {
      roles: ['advertiser', 'advert-admin', 'manager'],
      title: 'Marketplace',
      icon: 'menu-marketplace',
      alias: 'mp'
    }
  },
  {
    path: '/postbacks',
    component: Postbacks,
    name: 'postbacks',
    meta: {
      roles: ['advertiser', 'advert-admin', 'manager'],
      title: 'postbacks',
      icon: 'menu-postbacks',
      alias: 'pb'
    }
  },
  {
    path: '/statistics',
    component: Stats,
    name: 'stats',
    meta: {
      roles: [
        'publisher',
        'advertiser',
        'ad-exchange',
        'advert-admin',
        'manager'
      ],
      title: 'statistics',
      icon: 'menu-statistics',
      noParamsTransition: true,
      alias: 'st'
    }
  },
  {
    path: '/payments',
    component: Payments,
    name: 'payments',
    hidden: true,
    meta: {
      roles: ['advertiser', 'publisher', 'advert-admin', 'manager'],
      title: 'payments',
      icon: 'menu-payments',
      alias: 'pay'
    }
  },
  {
    path: '/payments',
    component: Payments,
    name: 'payments-admin',
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'payments',
      icon: 'menu-payments',
      alias: 'pay'
    }
  },
  {
    path: '/payments-publishers',
    component: PaymentsPubs,
    name: 'payments-pubs-admin',
    hidden: true,
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'payments',
      icon: 'menu-payments',
      alias: 'payp'
    }
  },
  {
    path: '/payments-ssp',
    component: PaymentsSsp,
    name: 'payments-ssp-admin',
    hidden: true,
    meta: {
      roles: ['advert-admin', 'manager'],
      title: 'SSP payments',
      icon: 'menu-payments',
      alias: 'payssp'
    }
  },
  {
    path: '/campaigns/add',
    component: CampaignAdd,
    hidden: true,
    name: 'campaign-add',
    meta: {
      title: 'Add campaign',
      roles: ['advertiser', 'publisher', 'advert-admin', 'manager']
    }
  },
  {
    path: '/campaigns/:id/copy',
    component: CampaignAdd,
    hidden: true,
    name: 'campaign-copy',
    meta: {
      isCopy: true,
      title: 'Copy campaign',
      roles: ['advertiser', 'advert-admin', 'manager']
    }
  },
  {
    path: '/rtb-campaigns/add',
    component: RtbCampaignForm,
    hidden: true,
    name: 'rtb-campaign-add',
    meta: {
      title: 'Add RTB campaign',
      roles: ['advert-admin', 'manager']
    }
  },
  {
    path: '/creatives',
    component: Creatives,
    name: 'creatives-list',
    meta: {
      roles: ['advertiser', 'advert-admin', 'manager'],
      title: 'Creatives',
      icon: 'menu-creatives',
      alias: 'cr'
    }
  },
  {
    path: '/landings',
    component: LandingGroupsList,
    name: 'landings-list',
    meta: {
      roles: ['advertiser'],
      title: 'Landings',
      icon: 'menu-landings',
      alias: 'l'
    }
  },
  {
    path: '/campaigns/:id/edit',
    component: CampaignAdd,
    hidden: true,
    name: 'campaign-edit',
    meta: {
      title: 'Edit campaign',
      isEdit: true,
      roles: ['advertiser', 'publisher', 'advert-admin', 'manager']
    }
  },
  {
    path: '/rtb-campaigns/:id/edit',
    component: RtbCampaignForm,
    hidden: true,
    name: 'rtb-campaign-edit',
    meta: {
      title: 'Edit RTB campaign',
      isEdit: true,
      roles: ['advert-admin', 'manager']
    }
  },
  ...siteRoutes,
  ...adunitRoutes,
  ...targetRoutes,
  ...syndicationDomainsRoutes,
  {
    path: '/users',
    component: Users,
    name: 'users',
    meta: {
      roles: ['admin', 'advert-admin', 'manager'],
      icon: 'menu-users',
      title: 'users',
      alias: 'u'
    }
  },
  ...adnetAdminRoutes,
  {
    path: '/contacts',
    component: Contacts,
    name: 'contacts',
    meta: {
      roles: ['publisher', 'advertiser', 'advert-admin', 'manager'],
      title: 'support',
      icon: 'menu-support',
      alias: 'sup'
    }
  },
  {
    path: '/privacy',
    component: Privacy,
    name: 'privacy',
    hidden: true
  },
  ...profileRoutes,
  ...errorRoutes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => ({ top: 0 }),
  linkExactActiveClass: 'is-active',
  linkActiveClass: 'is-active-parent',
  parseQuery(query) {
    const isNumeric = (num) => {
      return !isNaN(num);
    };
    return qs.parse(query, {
      encode: false,
      ignoreQueryPrefix: true,
      decoder: (str) => {
        if (str === 'false') return false;
        if (str === 'true') return true;
        if (isNumeric(str)) return +str;
        return decodeURI(str);
      }
    });
  },
  stringifyQuery(query) {
    return Object.getOwnPropertyNames(query).length > 0
      ? '?' + qs.stringify(query, { encode: false })
      : '';
  }
});

export default router;
export { routes };
