import lodash from 'lodash';

import dictionaries from './dictionaries';
import matcher from './matcher';

const stats = {
  segments: (state) => state.segments,
  metrics: (state) => state.metrics,
  reports: (state) => state.reports
};

const publisher = {
  landings: (state) => state.landings,
  adunits: (state) => state.adunits,
  getSiteSettingById: (state) => (id) => {
    return state.sitesSettings.find((setting) => setting.id === id);
  },
  getSitesByIds: (state) => (ids) => {
    if (ids === null) return [];
    return state.pubSites.filter((site) => ids.includes(site.id));
  },
  getAdunitById: (state) => (id) => {
    if (id === null) return [];
    return state.adunits.filter((item) => id === item.id);
  },
  getSiteLabelsById: (state) => (ids) => {
    if (ids === null) return [];
    return state.siteLabels.filter((label) => ids.includes(label.id));
  },
  pubSites: (state) => state.pubSites,
  siteLabels: (state) => state.siteLabels,
  adUnits: (state) => state.adUnits,
  sitesSettings: (state) => state.sitesSettings,
  directDeals: (state) => state.directDeals
};

const advert = {
  advertCampaigns: (state) => {
    if (Array.isArray(state.advertCampaigns.list)) return state.advertCampaigns;
    if (lodash.get(state, 'advertCampaigns.list.99999999', []).length === 0) {
      delete state.advertCampaigns.list['99999999'];
    }
    return state.advertCampaigns;
  },
  rtbCampaigns: (state) => state.rtbCampaigns,
  advertCreatives: (state) => state.advertCreatives,
  creatives: (state) => state.creatives,
  landingGroups: (state) => state.landingGroups,
  labels: (state) => state.labels,
  getLabelsByIds: (state) => (ids) => {
    if (!ids) {
      return [];
    }
    return state.labels
      .map((label) => {
        return ids.includes(label.id) ? label : false;
      })
      .filter(Boolean);
  },
  postbacks: (state) => state.postbacks,
  advertBalances: (state) => state.advertBalances,
  retargetGroups: (state) => state.retargetGroups,
  autoRules: (state) => state.autoRules,
  history: (state) => state.history
};

const dspIntegrations = {
  arbitrageSites: (state) => state.arbitrageSites,
  exchanges: (state) => state.exchanges,
  exchangePoints: (state) => state.exchangePoints,
  dspFeedBindings: (state) => state.dspFeedBindings,
  sspFeeds: (state) => state.sspFeeds,
  dspDirectFeedBindings: (state) => state.dspDirectFeedBindings,
  experiments: (state) => state.experiments,
  getDSPById: (state) => (id) => {
    if (id === null) return [];
    return state.dsp.filter((dsp) => id === dsp.id);
  },
  getSSPById: (state) => (id) => {
    if (id === null) return [];
    return state.ssp.filter((ssp) => id === ssp.id);
  },
  getFeedById: (state) => (id) => {
    if (id === null) return [];
    return state.dspFeeds.filter((dspFeed) => id === dspFeed.id);
  },
  getExchangeById: (state) => (id) => {
    if (id === null) return [];
    return state.exchanges.filter((item) => id === item.id);
  },
  codeTemplates: (state) => state.codeTemplates,
  dspFilters: (state) => state.dspFilters,
  dspFeeds: (state) => state.dspFeeds,
  dsp: (state) => state.dsp,
  ssp: (state) => state.ssp,
  dbEntities: (state) => state.dbEntities,
  getDSPFiltersByIds: (state) => (ids) => {
    if (ids === null) return [];
    return state.dspFilters.filter((filter) => ids.includes(filter.id));
  }
};

export default {
  ...dictionaries,
  ...matcher,
  ...stats,
  ...publisher,
  ...advert,
  ...dspIntegrations,
  getTargetsByIds: (state) => (ids) => {
    if (!ids) return [];
    return state.targets.filter((target) => ids.includes(target.id));
  },
  targets: (state) => state.targets
};
