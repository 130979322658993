import axios from 'axios';
import Vue from 'vue';

import * as auth from './../utils/auth';
import Api from './api';

export default (services, { token, refreshToken, app }) => {
  // TODO: Evaluate the future of Vue.api and decide on the best approach when migration to Vue 3 is more stable.
  Vue.api = {};
  Vue.api.registry = [];
  Vue.api.isRefreshing = false;
  Vue.api.refreshRequest = null;
  Vue.api.queue = [];

  window.onstorage = function (e) {
    if (e.key === 'jwt-refresh-tokens') {
      Vue.api.registry.forEach((name) => {
        const newVal = JSON.parse(e.newValue);
        const currentUser = window.localStorage.getItem('current-user');
        Vue.api[name].refreshToken = newVal[currentUser];
      });
    }
    if (e.key === 'jwt-tokens') {
      Vue.api.registry.forEach((name) => {
        const newVal = JSON.parse(e.newValue);
        const currentUser = window.localStorage.getItem('current-user');
        Vue.api[name].refreshToken = newVal[currentUser];
      });
    }
  };

  Vue.api.updateCredentials = ({ token, refreshToken, email }) => {
    auth.saveToken(token, email);
    auth.saveRefreshToken(refreshToken, email);

    Vue.api.registry.forEach((name) => {
      Vue.api[name].token = token;
      Vue.api[name].refreshToken = refreshToken;
    });
  };

  Vue.api.logout = () => {
    auth.deleteUser();
    auth.deleteActiveRole();

    Vue.api.registry.forEach((name) => {
      Vue.api[name].logout();
    });
    window.location.href = '/login';
  };

  Vue.api.client = axios;

  services.forEach((service) => {
    Vue.api.registry.push(service.name);
    let options = {
      baseUrl: service.baseUrl,
      token: token,
      refreshToken: refreshToken,
      authRequired: service.hasOwnProperty('authRequired')
        ? service.authRequired
        : false
    };
    if (service.lang) options.lang = true;
    if (service.role) options.role = true;
    Vue.api[service.name] = new Api(options);
  });

  app.config.globalProperties.$api = Vue.api;

  // TODO: Review project usage of `this.axios`. If unused, remove this global assignment.
  app.config.globalProperties.axios = axios;
  app.config.globalProperties.$http = axios;
};
