<template>
  <span @click="copy(copyText)">
    <slot></slot>
  </span>
</template>

<script>
import { useToastController } from 'bootstrap-vue-next';

export default {
  data() {
    return {
      toastController: useToastController()
    };
  },
  props: {
    copyText: {
      type: [String, Number],
      required: true,
      default: ''
    }
  },

  methods: {
    copy(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.toastController.show?.({
            props: {
              body: `Copied!`,
              toaster: 'b-toaster-top-center',
              solid: true,
              appendToast: false,
              noCloseButton: true,
              autoHideDelay: 1000,
              variant: 'success',
              toastClass: 'copy-toast',
              bodyClass: 'text-center'
            }
          });
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }
};
</script>
