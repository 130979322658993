import Vue from 'vue';

export default {
  fetchPubSites: (function () {
    let cancelPubSitesFetching;
    return function ({ commit, state }, data = {}) {
      if (typeof cancelPubSitesFetching === 'function') {
        cancelPubSitesFetching();
      }
      const params = {
        params: {
          ...data.params
        }
      };
      params.cancelToken = new Vue.api.client.CancelToken(function executor(c) {
        cancelPubSitesFetching = c;
      });
      return Vue.api.adnet
        .get('/ssp/sites', params)
        .then((res) => {
          const responseList = res.result === null ? [] : res.result;
          commit('SET_PUB_SITES', responseList);
          return res;
        })
        .catch((error) => {
          if (!Vue.api.client.isCancel(error)) {
            throw error;
          }
        });
    };
  })(),
  fetchSiteLabels({ commit }) {
    return Vue.api.adnet.get('/ssp/site-labels').then((res) => {
      const response = res.list === null ? [] : res.list;
      commit('SET_SITE_LABELS', response);
      return response;
    });
  },
  fetchSiteSettings({ commit, state }, refresh) {
    return Vue.api.adnet.get(`/ssp/sites-settings`).then((res) => {
      const response = res.list === null ? [] : res.list;
      commit('SET_SITES_SETTINGS', response);
      return response;
    });
  },
  fetchAdunits({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/ssp/adunit', params).then((res) => {
      const response = res.result === null ? [] : res.result;
      const setter = data.noIds ? 'SET_ADUNITS_NO_IDS' : 'SET_ADUNITS';
      if (!data.noCommit) commit(setter, response);
      return res;
    });
  },
  fetchDirectDeals({ getters, commit }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/ssp/direct-deal', params).then((res) => {
      commit('SET_DIRECT_DEALS', res.result);
      return res;
    });
  },
  fetchSyndicationDomains({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/ssp/syndication-domain', params).then((res) => {
      const response = res.list === null ? [] : res.list;
      commit('SET_SYNDICATION_DOMAINS', response);
      return response;
    });
  },
  fetchBlacklistAutoRules({ commit, state }, data = {}) {
    const params = data.params ? data.params : { limit: 99999 };
    return Vue.api.adnet
      .get(`/ssp/blacklist-auto-rules`, { params })
      .then((res) => {
        const response = res && res.result ? res.result : [];
        commit('SET_BLACKLIST_AUTO_RULES', response);
        return res;
      });
  }
};
