import { onClickOutside } from '@vueuse/core';

const HANDLERS_PROPERTY = '__v-click-outside';

const bind = (el, { value }) => {
  if (typeof value !== 'function') {
    throw new Error(
      'v-click-outside: Provided value must be a function to handle the click outside event.'
    );
  }

  el[HANDLERS_PROPERTY] = onClickOutside(el, (event) => {
    value(event);
  });
};

const unmounted = (el) => {
  if (el[HANDLERS_PROPERTY]) {
    el[HANDLERS_PROPERTY]?.();
    delete el[HANDLERS_PROPERTY];
  }
};

const update = (el, { value, oldValue }) => {
  if (value === oldValue) return;

  unmounted(el);
  bind(el, { value });
};

const clickOutsideDirective = {
  bind,
  update,
  unmounted
};

export default clickOutsideDirective;
