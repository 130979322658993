import Vue from 'vue';

import { getLastWeek, toUTCDateISOString } from '@/utils/date.js';

export default {
  fetchAdvertCampaigns({ commit }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    const dateRange = data.dateRange || {
      from: toUTCDateISOString(getLastWeek().start, true),
      to: toUTCDateISOString(new Date(), true)
    };

    params.params.from = dateRange.from;
    params.params.to = dateRange.to;
    let campaigns;
    return Vue.api.adnet
      .get(`/dsp/campaigns?stats=1`, params)
      .then(async (res) => {
        // let response = null;
        // if (res === null) {
        //   response = [];
        // } else if (res.hasOwnProperty('list')) {
        //   response = { list: res.list, totalRows: res.total };
        // } else {
        //   response = { list: res, totalRows: 0 };
        // }
        // campaigns = cloneDeep(response);

        // const result = campaigns;
        commit('SET_ADVERT_CAMPAIGNS', res.result);

        return res;
      });
  },
  fetchRtbCampaigns({ commit, state }, data = {}) {
    const params = data.params ? data.params : { params: { limit: 99999 } };
    return Vue.api.adnet.get(`/dsp/rtb-campaigns`, { params }).then((res) => {
      const response = res && res.result ? res.result : [];
      commit('SET_RTB_CAMPAIGNS', response);
      return res;
    });
  },
  fetchAutoRules({ commit, state }, data = {}) {
    const params = data.params ? data.params : { limit: 99999 };
    return Vue.api.adnet.get(`/dsp/cpa-auto-rules`, { params }).then((res) => {
      const response = res && res.result ? res.result : [];
      commit('SET_AUTO_RULES', response);
      return res;
    });
  },
  fetchCampaignSpots({ commit, state }, data = {}) {
    const params = data.params ? data.params : { limit: 99999 };
    const campaignId = data.campaignId || false;
    if (!campaignId) return false;
    return Vue.api.adnet
      .get(`/dsp/campaigns/${campaignId}/spots`, { params })
      .then((res) => {
        return res;
      });
  },
  fetchAdvertCreatives({ commit, state }, data = {}) {
    const params = data.params ? data.params : { params: { limit: 99999 } };
    return Vue.api.adnet.get(`/dsp/creatives`, { params }).then((res) => {
      const response = res && res.result ? res.result : [];
      commit('REPLACE_ADVERT_CREATIVES', response);
      return res;
    });
  },
  fetchLandings({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get(`/dsp/landings`, params).then((res) => {
      const response = res === null ? [] : res;
      commit('SET_LANDINGS', response);
      return response;
    });
  },
  fetchLandingGroups({ commit, state }, data = {}) {
    const params = data.params ? data.params : {};
    return Vue.api.adnet.get('/dsp/landing-groups', { params }).then((res) => {
      let responseList = res.result !== null ? res.result : [];
      commit('SET_LANDING_GROUPS', responseList);
      return res;
    });
  },
  updateLanding({ dispatch }, { payload = {} }) {
    if (!payload) return Promise.reject(false);
    return Vue.api.adnet
      .put('/dsp/landings/' + payload.id, payload)
      .then(() => {
        dispatch('fetchLandings', { refresh: true });
      });
  },
  async createLabel({ dispatch }, { payload = {} }) {
    if (!payload) return Promise.reject(false);
    const res = await Vue.api.adnet.post('/dsp/labels', payload);
    await dispatch('fetchLabels', { refresh: true });
    return res;
  },
  updateLabel({ dispatch }, { payload = {} }) {
    if (!payload) return Promise.reject(false);
    return Vue.api.adnet
      .put('/dsp/labels/' + payload.id, payload)
      .then((res) => {
        dispatch('fetchLabels', { refresh: true });
        return res;
      });
  },
  deleteLabel({ dispatch }, { payload = {} }) {
    if (!payload) return Promise.reject(false);
    return Vue.api.adnet
      .delete('/dsp/labels/' + payload.id, payload)
      .then((res) => {
        dispatch('fetchLabels', { refresh: true });
        return res;
      });
  },
  fetchLabels: (function () {
    let isGoing = null;

    return function ({ commit, state }, data = {}) {
      if (isGoing) return isGoing;
      isGoing = Vue.api.adnet.get('/dsp/labels').then((res) => {
        let response = res === null ? {} : res;
        response = response.list ? response.list : [];
        commit('SET_LABELS', response);
        isGoing = null;
        return response;
      });
      return isGoing;
    };
  })(),
  fetchPostbacks({ commit }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};

    return Vue.api.adnet.get('/dsp/goals', params).then((res) => {
      let response = res === null ? {} : res;
      response = response.result ? response.result : [];
      commit('SET_POSTBACKS', response);
      return res;
    });
  },
  createPostback(store, payload) {
    if (!payload) return Promise.reject(false);

    let method = payload.userData ? 'postByUser' : 'post';
    let requestArgs = ['/dsp/goals', payload.goal];
    if (payload.userData) {
      requestArgs.push({ ...payload.userData });
    }
    return Vue.api.adnet[method](...requestArgs).then((res) => {
      return res;
    });
  },
  updatePostback(store, payload) {
    if (!payload) return Promise.reject(false);
    return Vue.api.adnet
      .put('/dsp/goals/' + payload.id, payload)
      .then((res) => {
        return res;
      });
  },
  deletePostback(store, id) {
    if (!id) return Promise.reject(false);

    return Vue.api.adnet.delete('/dsp/goals/' + id).then((res) => {
      return res;
    });
  },
  getAutoCampaignRefreshStatus(store, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dsp/autocampaigns/refresh-status', params);
  },
  refreshAutoCampaigns(store, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dsp/autocampaigns/refresh', params);
  },
  fetchHistory({ commit, state }, data = {}) {
    const params = data.params ? data.params : { limit: 99999 };
    return Vue.api.adnet.get(`/history`, { params }).then((res) => {
      const response = res && res.result ? res.result : [];
      commit('SET_HISTORY', response);
      return res;
    });
  }
};
