import Vue from 'vue';

const state = {
  categories: [],
  taskTypes: [],
  managers: [],
  tokenStandards: [],
  currencies: [],
  taskRewardTypes: [],
  userCategories: [],
  userCountries: [],
  userCities: []
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },

  SET_TASK_TYPES(state, payload) {
    state.taskTypes = payload;
  },

  SET_MANAGERS(state, payload) {
    state.managers = payload;
  },

  SET_TOKEN_STANDARTS(state, payload) {
    state.tokenStandards = payload;
  },

  SET_CURRENCIES(state, payload) {
    state.currencies = payload;
  },

  SET_TASK_REWARD_TYPES(state, payload) {
    state.taskRewardTypes = payload;
  },

  SET_USER_CATEGORIES(state, payload) {
    state.userCategories = payload;
  },

  SET_USER_COUNTRIES(state, payload) {
    state.userCountries = payload;
  },

  SET_USER_CITIES(state, payload) {
    state.userCities = payload;
  }
};

const getters = {
  getRewardTypeIdByName: (state) => {
    return (name, taskType) => {
      if (state.taskRewardTypes.length === 0) return;
      const searchEl = state.taskRewardTypes.find((el) => {
        return el.title === name && el.taskType === taskType;
      });
      return searchEl ? searchEl.id : undefined;
    };
  },
  getRewardTypeNameById: (state) => {
    return (id) => {
      if (state.taskRewardTypes.length === 0) return;
      const searchEl = state.taskRewardTypes.find((el) => {
        return el.id === id;
      });
      return searchEl ? searchEl.title : undefined;
    };
  }
};

const actions = {
  fetchHunters(store, params) {
    return Vue.api.bounty
      .get('/v0/hunters', {
        params
      })
      .then((res) => res)
      .catch((e) => e);
  },
  fetchHunter(store, id) {
    return Vue.api.bounty
      .get(`/v0/hunters/${id}`)
      .then((res) => res)
      .catch((e) => e);
  },
  fetchCampaigns(store, params) {
    // { limit = 10, cursor }
    return Vue.api.bounty
      .get('/v0/campaigns', {
        params
      })
      .then((res) => res)
      .catch((e) => e);
  },
  fetchCampaign(store, { id }) {
    return Vue.api.bounty.get(`/v0/campaigns/${id}`);
  },
  fetchCampaignTask(store, { campaignId, taskId }) {
    return Vue.api.bounty.get(`/v0/campaigns/${campaignId}/tasks/${taskId}`);
  },
  fetchCategories({ commit, state }) {
    if (state.categories.length > 0) return Promise.resolve(state.categories);
    return Vue.api.bounty.get('/v0/campaign-categories').then(({ data }) => {
      commit('SET_CATEGORIES', data);
      return data;
    });
  },
  fetchTaskTypes({ commit, state }) {
    if (state.taskTypes.length > 0) return Promise.resolve(state.taskTypes);
    return Vue.api.bounty.get('/v0/campaign-task-types').then(({ data }) => {
      commit('SET_TASK_TYPES', data);
      return data;
    });
  },
  fetchManagers({ commit, state }) {
    if (state.managers.length > 0) return Promise.resolve(state.managers);
    return Vue.api.bounty.get('/v0/managers').then((res) => {
      commit('SET_MANAGERS', res);
      return res;
    });
  },
  fetchTokenStandards({ commit, state }) {
    if (state.tokenStandards.length > 0)
      return Promise.resolve(state.tokenStandards);
    return Vue.api.bounty.get('/v0/token-standards').then(({ data }) => {
      commit('SET_TOKEN_STANDARTS', data);
      return data;
    });
  },
  fetchCurrencies({ commit, state }) {
    if (state.currencies.length > 0) return Promise.resolve(state.currencies);
    return Vue.api.bounty.get('/v0/currencies').then(({ data }) => {
      commit('SET_CURRENCIES', data);
      return data;
    });
  },
  fetchTaskRewardTypes({ commit, state }) {
    if (state.taskRewardTypes.length > 0)
      return Promise.resolve(state.taskRewardTypes);
    return Vue.api.bounty
      .get('/v0/campaign-task-reward-types')
      .then(({ data }) => {
        commit('SET_TASK_REWARD_TYPES', data);
        return data;
      });
  },
  fetchUserCategories({ commit, state }) {
    if (state.userCategories.length > 0)
      return Promise.resolve(state.userCategories);

    let baseUrl = Vue.api.auth.options.baseUrl;
    baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    return Vue.api.auth
      .get('/user-categories', { baseURL: baseUrl })
      .then(({ userCategories }) => {
        commit('SET_USER_CATEGORIES', userCategories);
        return userCategories;
      });
  },
  fetchUserCountries({ commit, state }) {
    if (state.userCountries.length > 0)
      return Promise.resolve(state.userCountries);

    let baseUrl = Vue.api.auth.options.baseUrl;
    baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    return Vue.api.auth
      .get('/countries', { baseURL: baseUrl })
      .then(({ countries }) => {
        commit('SET_USER_COUNTRIES', countries);
        return countries;
      });
  },
  fetchUserCities({ commit, state }) {
    if (state.userCountries.length > 0)
      return Promise.resolve(state.userCities);

    let baseUrl = Vue.api.auth.options.baseUrl;
    baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    return Vue.api.auth
      .get('/cities--top-50-RF-pop-o100K', { baseURL: baseUrl })
      .then(({ Cities }) => {
        commit('SET_USER_CITIES', Cities);
        return Cities;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
