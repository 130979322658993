<template>
  <div class="select" :class="{ 'auto-width': autoWidth }">
    <button
      v-if="!button"
      type="button"
      class="btn-select"
      :class="[
        buttonClasses,
        {
          'is-disabled': disabled || (hideSelected && selectOptions.length <= 1)
        }
      ]"
      @click="toggleCheckboxes"
      :disabled="disabled || (hideSelected && selectOptions.length <= 1)"
    >
      <div class="button-label">
        <span
          class="selected-values"
          :class="{ 'is-excluded': exclude, 'd-flex': showOptions }"
          v-html="getBtnLabel"
        ></span>
        <span class="caret">
          <svg-icon icon="chevron-down" class="icon-xs" />
        </span>
      </div>
    </button>
    <div
      v-if="
        !this.options.multi &&
        showSelectedDescription &&
        valueSelected.length &&
        valueSelected[0].description
      "
      class="selected-description text-muted mt-1"
    >
      {{ valueSelected[0].description }}
    </div>
    <slot v-else name="right-button" :click-handler="toggleCheckboxes" />
    <transition name="fade">
      <div
        class="checkbox-layer"
        :class="{ show: isOpen, right: align === 'right' }"
        v-click-outside="externalClick"
        v-show="isOpen"
      >
        <div
          v-if="search || hasExcludeMode"
          class="helper-container helper-container--top"
        >
          <div v-if="search" class="line" style="position: relative">
            <svg-icon icon="search" class="line__search" />
            <input
              :placeholder="searchPlaceholder"
              type="text"
              v-model="searchInput"
              @input="searchfn()"
              autocomplete="nope"
              class="form-control"
              :class="{ 'no-right-border-radius': button }"
            />
          </div>
          <template v-if="hasExcludeMode">
            <button
              class="btn exclude-switcher"
              :class="{ 'is-active': exclude }"
              @click="excludeSwitch()"
            >
              {{ $t('excludeMode') }}: <span>{{ exclude ? 'ON' : 'OFF' }}</span>
            </button>
          </template>
        </div>
        <div class="check-box-container">
          <ul class="select-list">
            <li
              v-for="(option, indexOptions) in globalModel"
              :key="indexOptions"
              :data-option="option[idName]"
              class="select-item"
              :class="{
                disabled:
                  option.disabled ||
                  (maxLimit && maxLimit === count && !option.selected),
                selected: option.selected,
                hideSelected: option.selected && hideSelected,
                'd-none': !option.selected && showOnlySelected,
                'fw-bold': highlight(option)
              }"
              v-show="option.visible"
              @click="selectOption(option)"
            >
              <slot :option="option" :render-template="renderTemplate">
                <span
                  v-if="multi"
                  class="label-check"
                  :class="{ 'is-checked': option.selected }"
                ></span>
                <span class="label-name">
                  {{ renderTemplate(option) }}
                  <svg-icon
                    v-if="option.description"
                    icon="question-circle"
                    class="icon-xs description align-middle"
                    v-b-tooltip.hover="option.description"
                /></span>
              </slot>
            </li>
          </ul>
          <div v-if="!valueSelected || optionsAllHide" class="empty-tab">
            {{ $t('noData') }}
          </div>
        </div>
        <div
          v-if="multi || options.showOnlyClearBtn"
          class="helper-container bottom-controls"
        >
          <div class="line d-flex align-items-center">
            <div
              v-if="
                onlySelected &&
                valueSelected.length > 0 &&
                !options.showOnlyClearBtn
              "
              class="show-only-selected helper-button clear-btn"
              @click="toggleSelected"
            >
              <span
                class="label-check"
                :class="{ 'is-checked': showOnlySelected }"
              ></span>
              {{ $t('showOnlySelected') }}
            </div>
            <button
              type="button"
              class="helper-button clear-btn pb-0 pt-0 ms-auto"
              @click="clearSelected()"
            >
              {{ $t('clear') }}
            </button>
            <button
              v-if="!hideSubmitBtn && !options.showOnlyClearBtn"
              type="button"
              class="btn btn-primary btn-sm ms-2"
              @click="isOpen = false"
            >
              {{ $t('ok') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';

/* Вынужден предупредить что при включеной,
  ф-ии ексклюд мода, v-model не работает и стоит юзать
  @input евент в родительском компоненте */

export default {
  name: 'MultiSelect',
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Array,
      default: () => []
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    btnLabel: {
      type: String,
      default: 'Select'
    },
    btnClass: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'left'
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...'
    },
    hasExcludeMode: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    zeroText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLimit: {
      type: [Number, Boolean],
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    showOptions: {
      type: Boolean,
      default: false
    },
    onlySelected: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    hideSubmitBtn: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Function,
      default: () => {}
    },
    showSelectedDescription: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueSelected: [],
      multiSelect: null,
      exclude: false,
      isOpen: false,
      globalModel: [],
      searchInput: '',
      optionsAllHide: false,
      showOnlySelected: false
    };
  },
  created() {
    this.setConfig();
  },
  methods: {
    setConfig() {
      this.multi = Boolean(this.options.multi) || false;
      this.labelName = this.options.labelName || 'name';
      this.idName = this.options.idName || 'id';
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.unshift({
        nameAll: 'Select all',
        nameNotAll: 'Deselect all',
        func: () => true
      });
      this.init();
    },
    init() {
      let clone = cloneDeep(this.selectOptions);

      if (typeof clone[0] === 'string' || typeof clone[0] === 'number') {
        clone = this.prepareArray(clone);
        this.simpleArray = true;
      }
      this.globalModel = clone;
      this.initValues();
    },
    initValues() {
      this.valueSelected = [];
      this.deselctAll();
      for (let j = 0; j < this.globalModel.length; j += 1) {
        this.globalModel[j].visible = true;

        if (typeof this.globalModel[j].selected !== 'boolean') {
          this.globalModel[j].selected = false;
        }
        for (let k = 0; k < this.modelValue.length; k += 1) {
          if (
            this.simpleArray &&
            this.globalModel[j][this.labelName] === this.modelValue[k]
          ) {
            this.globalModel[j].selected = true;
            this.valueSelected.push(this.globalModel[j][this.labelName]);
          } else if (
            !this.simpleArray &&
            this.globalModel[j][this.labelName] ===
              this.modelValue[k][this.labelName]
          ) {
            this.globalModel[j].selected = true;
            const opt = Object.assign({}, this.globalModel[j]);
            delete opt.selected;
            delete opt.visible;
            this.valueSelected.push(opt);
          }
        }
      }
      this.filter();
      !this.modelValue.length && this.emitInput();
    },
    toggleCheckboxes(event) {
      this.multiSelect = event.target;
      if (this.multiSelect.className === 'button-label') {
        this.multiSelect = this.multiSelect.parentNode;
      }
      this.isOpen = !this.isOpen;
    },
    externalClick(event) {
      if (this.isOpen) {
        let elem = event.target;
        if (!!elem && elem.className === 'button-label') {
          elem = elem.parentNode;
        }
        if (!!elem && elem.isSameNode(this.multiSelect)) {
          return;
        }
        this.isOpen = false;
      }
    },
    selectOption(option) {
      if (option.disabled) return;
      if (!option.selected) {
        if (!this.multi) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters[0].selectAll = true;
          this.deselctAll();
          this.valueSelected = [];
          this.emitInput();
          this.externalClick({ path: [] });
        }
        this.pushOption(option);
        this.emitInput();
      } else {
        this.popOption(option);
        this.emitInput();
      }
      option.selected = !option.selected;
      this.filter();
    },
    pushOption(option) {
      if (this.simpleArray) {
        this.valueSelected.push(option[this.labelName]);
      } else {
        const opt = Object.assign({}, option);
        delete opt.selected;
        delete opt.visible;
        this.valueSelected.push(opt);
      }
    },
    popOption(opt) {
      for (let i = 0; i < this.valueSelected.length; i += 1) {
        if (
          this.valueSelected[i][this.labelName] === opt[this.labelName] ||
          (this.simpleArray && this.valueSelected[i] === opt[this.labelName])
        ) {
          this.valueSelected.splice(i, 1);
          return;
        }
      }
    },
    searchfn() {
      let allHide = true;
      for (let i = 0; i < this.globalModel.length; i += 1) {
        if (
          ~this.globalModel[i][this.labelName]
            .toLowerCase()
            .indexOf(this.searchInput.toLowerCase())
        ) {
          allHide = false;
          this.globalModel[i].visible = true;
        } else {
          this.globalModel[i].visible = false;
        }
      }
      this.optionsAllHide = allHide;
      this.filter();
    },
    filter() {
      for (let i = 0; i < this.filters.length; i += 1) {
        let allSelected = true;
        for (let j = 0; j < this.globalModel.length; j += 1) {
          if (
            this.globalModel[j].visible &&
            this.filters[i].func(this.globalModel[j]) &&
            !this.globalModel[j].disabled &&
            !this.globalModel[j]['selected']
          ) {
            allSelected = false;
            break;
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[i].selectAll = allSelected;
      }
    },
    deselctAll() {
      for (let i = 0; i < this.globalModel.length; i += 1) {
        if (!this.globalModel[i].disabled) {
          this.globalModel[i].selected = false;
        }
      }
    },
    prepareArray(value) {
      return value.map((elem) => ({ [this.labelName]: elem }));
    },
    clearSelected() {
      this.showOnlySelected = false;
      this.deselctAll();
      this.valueSelected = [];
      this.emitInput();
    },
    excludeSwitch() {
      this.exclude = !this.exclude;
      this.emitInput();
    },
    emitInput() {
      if (this.exclude) {
        const excluded = this.selectOptions.filter(
          (el) => !this.valueSelected.find((obj) => isEqual(obj, el))
        );
        this.$emit('update:modelValue', [...excluded]);
      } else {
        this.$emit('update:modelValue', [...this.valueSelected]);
      }
    },
    toggleSelected() {
      this.showOnlySelected = !this.showOnlySelected;
    }
  },
  computed: {
    getBtnLabel() {
      if (!this.multi) {
        const label = this.valueSelected[this.valueSelected.length - 1];
        if (!label) return this.btnLabel;
        return `${
          label !== null && typeof label === 'object'
            ? label[this.labelName]
            : label
        }`;
      }
      let label = this.btnLabel;
      if (this.valueSelected.length > 0) {
        label = this.valueSelected.map((item) => item.name).join(', ');
      }
      const labelClass = `${
        this.showOptions ? 'w-100 overflow-hidden text-ellipsis pe-2 ' : ''
      }align-middle`;
      const countClass = `${
        this.showOptions ? 'ms-auto ' : ''
      }small text-light align-middle`;
      if (this.multi && this.exclude) {
        return `<span class="${labelClass}">${label}</span>
          <i class="${countClass}">(${this.valueSelected.length} exclude)</i>`;
      }
      if (this.multi && !this.exclude) {
        return `<span class="${labelClass}">${label}</span>
          <i class="${countClass}">(${
            this.zeroText !== '' && this.valueSelected.length === 0
              ? this.zeroText
              : this.valueSelected.length
          })</i>`;
      }

      return this.btnLabel;
    },
    buttonClasses() {
      const propClasses = {};
      this.btnClass.split(' ').forEach((el) => {
        propClasses[el] = true;
      });

      return {
        'is-active': this.isOpen,
        'btn-select-lg': this.size === 'lg',
        'btn-select-md': this.size === 'md',
        'btn-select-sm': this.size === 'sm',
        'is-invalid': this.isInvalid,
        ...propClasses
      };
    },
    renderTemplate() {
      return this.options.renderTemplate || ((elem) => elem[this.labelName]);
    },
    count() {
      return this.modelValue.length;
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (!isEqual(newVal, this.valueSelected)) {
          this.initValues();
        }
      },
      deep: true
    },
    selectOptions: {
      handler() {
        this.setConfig();
      },
      deep: true
    },
    'options.multi': function () {
      this.setConfig();
    },
    isOpen(val) {
      this.$emit(val ? 'opened' : 'closed');
    }
  }
};
</script>

<style>
.text-ellipsis {
  text-overflow: ellipsis;
}
</style>

<style lang="scss" scoped>
/* ! vertical layout */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background: $secondary;
  border-radius: 3px;
}

.select {
  text-align: left;
  position: relative;
  width: 100%;
  line-height: 0;

  .selected-description {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }

  &.auto-width {
    width: auto;
  }

  .line {
    box-sizing: content-box;
    overflow: hidden;
    position: relative;
  }

  & > .btn-select {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    color: $body-color;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    font-size: 1em;
    padding: $input-padding-y $input-padding-x;
    position: relative;
    text-align: center;
    user-select: none;
    white-space: normal;
    background-color: $input-bg;
    height: $input-height;
    @extend %base-transition;
    outline: none;

    &.is-disabled {
      cursor: default;
      background-color: $disabled-color;
    }

    &.is-invalid {
      border-color: $danger;
    }

    &.btn-select-lg {
      height: $input-height-lg;
      padding: $input-padding-y-lg $input-padding-x-lg;
      border-radius: $input-border-radius-lg;

      .button-label {
        font-size: $font-size-lg;
        line-height: 100%;
      }
    }

    &.btn-select-sm {
      height: $input-height-sm;
      padding: $input-padding-y-sm $input-padding-x-sm;
      font-size: $font-size-sm;
      border-radius: 0.2rem;

      .button-label {
        font-size: $font-size-sm;
        line-height: 100%;
      }
    }
  }

  .button-label {
    word-break: break-word;
    display: inline-block;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: 1px;
    width: 100%;
    text-align: left;
    padding: 0;
    position: relative;
    top: 1px;

    .selected-values {
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      padding-right: 20px;
      line-height: 100%;
      overflow: hidden;
    }
  }

  .caret {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: $light;
    margin-top: 1px;

    i {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .btn-select.is-active {
    border-color: $primary;
    box-shadow: inset 0 0 0 1px $primary;
    z-index: 2;

    .caret {
      transform: rotate(180deg) translateY(50%);
    }
  }

  .checkbox-layer {
    background-color: $input-bg;
    position: absolute;
    top: 100%;
    margin-top: 10px;
    width: 100%;
    z-index: 1025;
    border: 1px solid $border-color;
    border-radius: 4px;
    box-shadow: 0 9px 31px 0 rgba(83, 83, 97, 0.08);
    min-width: 278px;
    display: none !important;

    &.right {
      right: 0;
    }
  }

  .helper-container {
    padding: 10px;

    &:empty {
      display: none;
    }

    .exclude-switcher {
      font-weight: 800;
      text-transform: uppercase;
      font-size: 0.625rem;
      letter-spacing: 1px;
      background: none;
      border: 1px solid $border-color;
      border-radius: 3px;
      margin-top: 10px;

      &:first-child {
        margin-top: 0px;
      }

      &.is-active {
        background-color: $lighten-bg;
        border: 1px solid transparentize($success, 0.42);

        span {
          color: tint-color($success, 5%);
        }
      }
    }
  }

  .helper-button {
    display: inline;
    text-align: center;
    cursor: pointer;
    border: 1px solid $border-color;
    font-size: 13px;
    border-radius: 2px;
    color: $light;
    line-height: 1.6;
    margin: 0px 0px 8px 0px;
    outline: none;
    border-bottom: 1px solid $border-color;
  }

  .bottom-controls {
    border-bottom: none;

    .helper-button {
      border: none;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0;

      &.apply-btn {
        float: right;
        color: $primary;
      }

      &.clear-btn {
        float: left;
        color: $body-color;
      }
    }
  }

  .check-box-container {
    display: block;
    overflow: hidden;
    max-height: 270px;
    overflow-y: auto;
  }

  .show {
    display: block !important;
  }

  .select-item {
    display: block;
    padding: 10px;
    font-size: 13px;
    white-space: nowrap;
    user-select: none;
    border: 1px solid transparent;
    position: relative;
    margin-top: 0;
    line-height: 20px;

    &:hover {
      cursor: pointer;
      background-color: $light-bg;
    }

    span:hover {
      cursor: pointer;
    }

    .label-name {
      position: relative;
      font-size: 1rem;
      display: inline-block;
      vertical-align: middle;
      top: -1px;
      white-space: normal;
      padding-right: 20px;
    }

    &.selected {
      cursor: pointer;
      background-color: $lighten-bg;

      &.hideSelected {
        display: none;
      }
    }
  }

  .disabled,
  .disabled:hover,
  .disabled label input:hover ~ span {
    //color: $custom-select-disabled-bg !important;
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

.label-check {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  pointer-events: none;
  user-select: none;
  background-color: $gray-300;
  border-radius: 0.25rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:after {
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &.is-checked {
    color: $white;
    background-color: $secondary;

    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }
  }
}

.select-list {
  margin: 0;
  list-style: inside disc;
  padding-left: 0px;
}

.line {
  &__search {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
  .form-control {
    padding-left: 35px;

    &:focus {
      box-shadow: inset 0 0 0 1px $secondary;
    }
  }
  .show-only-selected {
    .label-check {
      margin-right: 5px;
    }
  }
}

.select-site-labels {
  position: absolute;
  top: 2px;
  right: 2px;
}

:slotted(.right-button) {
  height: calc(1.5em + 1rem - 2px);
  padding: 0 1rem;
  border: none;
  outline: none;
  color: $lighten;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

@include color-mode(dark) {
  .select .select-item:hover {
    color: $dark;
  }
  .select .select-item.selected {
    color: $dark;
  }
  .select > .btn-select {
    color: $light;
    border-color: $dark-border-color;
  }
}
</style>
