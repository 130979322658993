const Login = () => import('@/views/Login/Login.vue');
const Forgot = () => import('@/views/Login/Forgot.vue');
const Register = () => import('@/views/Login/Register.vue');
const Edit = () => import('@/views/SettingsProfile/Edit.vue');
const EmailConfirm = () => import('@/views/SettingsProfile/EmailConfirm.vue');
const ProfileUser = () => import('@/views/Profile/Admin/ProfileUser.vue');
const AcceptInviteForm = () => import('@/views/Login/AcceptInvite.vue');
import * as auth from '@/utils/auth.js';

const routes = [
  {
    path: '/login',
    component: Login,
    name: 'login',
    hidden: true,
    meta: {
      layout: 'empty'
    },
    beforeEnter: (to, from, next) => {
      if (auth.readToken()) {
        next('/dashboard');
      } else {
        next();
      }
    }
  },
  {
    path: '/forgot',
    component: Forgot,
    name: 'forgot',
    hidden: true,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/confirm-reset',
    component: Forgot,
    name: 'confirm-reset',
    hidden: true,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/register',
    component: Register,
    name: 'registration',
    hidden: true,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/confirm-invite',
    component: AcceptInviteForm,
    name: 'accept-invite',
    hidden: true,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/email-confirm',
    component: EmailConfirm,
    name: 'email-confirm',
    hidden: true,
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/edit',
    name: 'user-settings',
    component: Edit,
    hidden: true,
    meta: {
      title: 'Profile',
      alias: 'prf'
    }
  },
  {
    path: '/users/:userId',
    component: ProfileUser,
    hidden: true,
    name: 'profile-admin',
    meta: {
      roles: ['admin', 'bounty-admin', 'advert-admin', 'manager']
    }
  }
];

export default routes;
