const DEFAULT_RULES = [];

const ADMIN_RULES = [
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'Adunit',
    action: 'create'
  }
];

const BOUNTY_ADMIN_RULES = [
  {
    subject: ['Campaign', 'Task'],
    action: 'update-status'
  },
  {
    subject: ['Campaign', 'Task'],
    action: 'update'
  },
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'TaskParticipants',
    action: 'manage'
  },
  {
    subject: 'TaskDetailedStatistics',
    action: 'view'
  },
  {
    subject: 'TaskDetailedStatistics',
    action: 'update'
  }
];

const ADVERT_ADMIN_RULES = [
  {
    subject: ['Campaign', 'Task'],
    action: 'update-status'
  },
  {
    subject: ['Campaign', 'Task'],
    action: 'update'
  },
  {
    subject: ['Creative'],
    action: 'update'
  },
  {
    subject: 'Landing',
    action: 'change-status'
  },
  {
    subject: 'TaskDetailedStatistics',
    action: 'update'
  },
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'BackupFeedBinding',
    action: 'add'
  },
  {
    subject: 'Fee',
    action: 'add'
  },
  {
    subject: 'Creative',
    action: 'edit'
  },
  {
    subject: 'Creative',
    action: 'moderate'
  },
  {
    subject: 'CreativeReview',
    action: 'get'
  },
  {
    subject: 'Campaigns',
    action: 'add'
  },
  {
    subject: 'Campaigns',
    action: 'moderate'
  },
  {
    subject: 'Campaigns',
    action: 'refresh'
  },
  {
    subject: 'Adunit',
    action: 'create'
  },
  {
    subject: 'AdUnitMultiplexer',
    action: 'set'
  }
];

const MANAGER_RULES = [
  {
    subject: ['Campaign', 'Task'],
    action: 'update-status'
  },
  {
    subject: ['Campaign', 'Task'],
    action: 'update'
  },
  {
    subject: ['Creative'],
    action: 'update'
  },
  {
    subject: 'Landing',
    action: 'change-status'
  },
  {
    subject: 'TaskDetailedStatistics',
    action: 'update'
  },
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'BackupFeedBinding',
    action: 'add'
  },
  {
    subject: 'Fee',
    action: 'add'
  },
  {
    subject: 'Creative',
    action: 'edit'
  },
  {
    subject: 'Creative',
    action: 'moderate'
  },
  {
    subject: 'CreativeReview',
    action: 'get'
  },
  {
    subject: 'Campaigns',
    action: 'add'
  },
  {
    subject: 'Campaigns',
    action: 'moderate'
  },
  {
    subject: 'Campaigns',
    action: 'refresh'
  },
  {
    subject: 'Adunit',
    action: 'create'
  },
  {
    subject: 'AdUnitMultiplexer',
    action: 'set'
  }
];

const PUBLISHER_RULES = [
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'Adunit',
    action: 'create'
  },
  {
    subject: 'unpaidBalance',
    action: 'show'
  }
];

const ADVERTISER_RULES = [
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'Landing',
    action: ['delete', 'update', 'create']
  },
  {
    subject: 'Creative',
    action: 'getInfo'
  },
  {
    subject: 'Label',
    action: 'crud'
  },
  {
    subject: 'Campaigns',
    action: 'group'
  },
  {
    subject: 'Campaigns',
    action: 'add'
  },
  {
    subject: 'Balance',
    action: 'show'
  }
];

const BOUNTY_HUNTER_RULES = [
  {
    subject: 'BountyCampaign',
    action: 'search'
  },
  {
    subject: 'LinkStatus',
    action: 'view'
  },
  {
    subject: 'TaskProgress',
    action: 'view'
  },
  {
    subject: 'Task',
    action: 'join'
  },
  {
    subject: 'InstagramPost',
    action: 'submit'
  },
  {
    subject: 'CreativeTask',
    action: 'submit'
  },
  {
    subject: 'Payments',
    action: 'visit'
  },
  {
    subject: 'BountyHunterInfo',
    action: 'setup'
  }
];

const BOUNTY_MANAGER_RULES = [
  {
    subject: 'BountyCampaign',
    action: 'search'
  },
  {
    subject: 'LinkStatus',
    action: 'change'
  },
  {
    subject: ['Campaign', 'Task'],
    action: 'update',
    conditions: {
      status: 'pending'
    }
  },
  {
    subject: 'TaskParticipants',
    action: 'manage'
  },
  {
    subject: 'TaskDetailedStatistics',
    action: 'view'
  },
  {
    subject: 'TaskDetailedStatistics',
    action: 'update'
  }
];

const ABILITIES = new Map([
  ['default', DEFAULT_RULES],
  ['admin', ADMIN_RULES],
  ['bounty-admin', BOUNTY_ADMIN_RULES],
  ['advert-admin', ADVERT_ADMIN_RULES],
  ['manager', MANAGER_RULES],
  ['publisher', PUBLISHER_RULES],
  ['advertiser', ADVERTISER_RULES],
  ['bounty-hunter', BOUNTY_HUNTER_RULES],
  ['bounty-manager', BOUNTY_MANAGER_RULES]
]);

export default ABILITIES;
