<style lang="scss" scoped>
.box {
  background: $container-bg;
  border: 1px solid $border-color;
  padding: 2.5rem;
  border-radius: $border-radius;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

@include color-mode(dark) {
  .box {
    background: $container-bg-dark;
    border-color: $dark-border-color;
  }
}

@include media-breakpoint-down(sm) {
  .box {
    padding: 1.5rem;
  }
}
</style>

<script>
import { defineComponent, h } from 'vue';

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  },
  setup(props, { slots }) {
    return () => {
      return h(
        props.tag,
        {
          class: {
            box: true
          }
        },
        slots.default ? slots.default() : []
      );
    };
  }
});
</script>
