import { useToastController } from 'bootstrap-vue-next';
import set from 'lodash/set';

function registerErrorMessages(app) {
  function $processMessage(ctx, message, simpleMessage) {
    const messageId = message.type ? message.type.split('urn:uuid:')[1] : false;

    let mess = '';
    if (!message.type) {
      mess = simpleMessage
        ? message.title
        : ctx.$t('bountyMessages.unknownError');
    } else {
      mess = ctx.$t(`bountyMessages.${messageId}`);
    }

    if (mess && mess.includes('bountyMessages.')) {
      return simpleMessage
        ? message.title
        : ctx.$t('bountyMessages.unknownError');
    } else if (
      !message.parameters ||
      Object.keys(message.parameters).length === 0
    ) {
      return mess;
    } else {
      let processedMess = mess;
      Object.keys(message.parameters).forEach((key) => {
        let type = message.parameters[key.toLowerCase()];
        if (
          message.parameters[key.toLowerCase()] === 'comment' ||
          message.parameters[key.toLowerCase()] === 'post'
        ) {
          type = `${message.parameters[key.toLowerCase()]}One`;
        }
        processedMess = processedMess.replace(
          `{{ ${key.toLowerCase()} }}`,
          ctx.$t(type)
        );
      });

      return processedMess;
    }
  }

  app.config.globalProperties.$parseErrors = function (errorsArr, errorDetail) {
    const errors = {};

    errorsArr.forEach((el) => {
      set(
        errors,
        el.propertyPath,
        errorDetail ? el.detail : $processMessage(this, el)
      );
    });

    return errors;
  };

  app.config.globalProperties.$parseAuthErrors = function (error) {
    const errors = {};

    if (error.errors) {
      error.errors.forEach((el) => {
        errors[el.field] = el.message;
      });
    } else {
      errors[error.field] = error.message;
    }

    return errors;
  };

  app.config.globalProperties.$parseMessage = function (message) {
    const customTextId = '1a4feb44-04b7-445c-97ae-b1341910fdfe';
    const messageId = message.type ? message.type.split('urn:uuid:')[1] : false;

    return messageId === customTextId
      ? message.title
      : $processMessage(this, message, true);
  };

  app.config.globalProperties.$alertError = function (error) {
    const toastController = this.$root.$refs.toastController;

    if (error === 'console-alert') return;
    toastController.showToast?.({
      props: {
        body: error || 'Something went wrong',
        title: `Error`,
        variant: 'danger',
        autoHideDelay: 5000,
        solid: true
      }
    });
  };
}

export default registerErrorMessages;
