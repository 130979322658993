export const saveToken = (token, email) => {
  let tokens = localStorage.getItem('jwt-tokens')
    ? JSON.parse(localStorage.getItem('jwt-tokens'))
    : {};
  tokens[email.toLowerCase()] = token;
  return localStorage.setItem('jwt-tokens', JSON.stringify(tokens));
};

export const saveFp = (fp, email) => {
  return localStorage.setItem(`${email.toLowerCase()}-user-fp`, fp);
};

export const getFp = (email) => {
  return localStorage.getItem(`${email}-user-fp`);
};

export const readToken = () => {
  const email = readUser();
  const tokens = localStorage.getItem('jwt-tokens')
    ? JSON.parse(localStorage.getItem('jwt-tokens'))
    : {};
  return email && tokens[email] ? tokens[email] : false;
};

export const deleteToken = () => {
  const email = readUser();
  let tokens = localStorage.getItem('jwt-tokens')
    ? JSON.parse(localStorage.getItem('jwt-tokens'))
    : null;
  if (!tokens || !email) {
    return;
  }
  delete tokens[email];
  return localStorage.setItem('jwt-tokens', JSON.stringify(tokens));
};

export const saveUser = ({ email }) => {
  return localStorage.setItem('current-user', email.toLowerCase());
};

export const readUser = () => {
  return localStorage.getItem('current-user');
};

export const deleteUser = () => {
  return localStorage.removeItem('current-user');
};

export const saveRefreshToken = (token, email) => {
  if (token) {
    let tokens = localStorage.getItem('jwt-refresh-tokens')
      ? JSON.parse(localStorage.getItem('jwt-refresh-tokens'))
      : {};
    tokens[email.toLowerCase()] = token;
    return localStorage.setItem('jwt-refresh-tokens', JSON.stringify(tokens));
  } else return false;
};

export const readRefreshToken = () => {
  const email = readUser();
  const tokens = localStorage.getItem('jwt-refresh-tokens')
    ? JSON.parse(localStorage.getItem('jwt-refresh-tokens'))
    : {};
  return email && tokens[email] ? tokens[email] : false;
};

export const deleteRefreshToken = () => {
  const email = readUser();
  const tokens = localStorage.getItem('jwt-refresh-tokens')
    ? JSON.parse(localStorage.getItem('jwt-refresh-tokens'))
    : null;
  if (!tokens || !email) {
    return;
  }
  delete tokens[email];
  return localStorage.setItem('jwt-refresh-tokens', JSON.stringify(tokens));
};

export const saveActiveRole = (role) => {
  return localStorage.setItem('role', role);
};

export const readActiveRole = () => {
  return localStorage.getItem('role');
};

export const deleteActiveRole = () => {
  return localStorage.removeItem('role');
};

export const readFakeLogin = () => {
  return localStorage.getItem('f-login')
    ? JSON.parse(localStorage.getItem('f-login'))
    : {};
};

export const saveFakeLogin = (adminEmail = null, userEmail = null) => {
  console.log(`Save fake login - AE: ${adminEmail} | E: ${userEmail}`);
  if (!adminEmail || !userEmail) return localStorage.setItem(`f-login`, null);
  let list = localStorage.getItem('f-logins-list')
    ? JSON.parse(localStorage.getItem('f-logins-list'))
    : [];

  if (!list.includes(userEmail)) {
    list.push(userEmail);
    localStorage.setItem(`f-logins-list`, JSON.stringify(list));
  }
  console.log(JSON.stringify({ admin: adminEmail, user: userEmail }));

  localStorage.setItem(
    `f-login`,
    JSON.stringify({ admin: adminEmail, user: userEmail })
  );
};

export const readFakeLoginsList = () => {
  return localStorage.getItem('f-logins-list')
    ? JSON.parse(localStorage.getItem('f-logins-list'))
    : [];
};
