import { computed, getCurrentInstance, watch } from 'vue';

import ability from '../plugins/ability';
import store from '../store';

export function useRoleWatcher() {
  const role = computed(() => store.getters.role);
  const instance = getCurrentInstance();

  watch(
    role,
    (newRole) => {
      if (newRole) {
        const newAbilities = ability.getRoleAbilities(newRole);
        instance.appContext.app.config.globalProperties.$ability.update(
          newAbilities
        );
      }
    },
    { immediate: true }
  );
}
