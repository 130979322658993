const statuses = [
  {
    id: 1,
    name: 'Approved'
  },
  {
    id: 2,
    name: 'Reviewing'
  },
  {
    id: 3,
    name: 'Rejected'
  }
];

export const AD_TYPE_BANNER = 1;
export const AD_TYPE_VIDEO = 2;
export const AD_TYPE_PUSH = 3;
export const AD_TYPE_POPUNDER = 4;
export const AD_TYPE_NATIVE = 5;
export const AD_TYPE_INTERSTITIAL = 6;
export const AD_TYPE_VIDEO_SLIDER = 8;
export const AD_TYPE_LINK = 9;
export const AD_TYPE_OUTSTREAM = 11;

export const CREATIVE_TYPE_BY_NAME = {
  image: 1,
  video: 2
};

export const CUSTOM_CONTENT_AVAILABLE = [
  AD_TYPE_BANNER,
  AD_TYPE_VIDEO,
  AD_TYPE_INTERSTITIAL,
  AD_TYPE_VIDEO_SLIDER,
  AD_TYPE_OUTSTREAM
];

export default {
  statuses
};

export const utcTimezones = [
  'UTC',
  'UTC+1',
  'UTC+2',
  'UTC+3',
  'UTC+4',
  'UTC+5',
  'UTC+6',
  'UTC+7',
  'UTC+8',
  'UTC+9',
  'UTC+10',
  'UTC+11',
  'UTC+12',
  'UTC-1',
  'UTC-2',
  'UTC-3',
  'UTC-4',
  'UTC-5',
  'UTC-6',
  'UTC-7',
  'UTC-8',
  'UTC-9',
  'UTC-10',
  'UTC-11',
  'UTC-12'
];

// some grants for routes permission
export const DIRECT_DEAL_CREATE = 17;
export const DIRECT_DEAL_UPDATE = 18;
export const DIRECT_DEAL_GET = 20;
export const DIRECT_DEAL_CREATIVE_CREATE = 23;
export const DIRECT_DEAL_CREATIVE_UPDATE = 24;
export const DIRECT_DEAL_CREATIVE_LIST = 25;
export const DIRECT_DEAL_CREATIVE_GET = 26;
