import Vue from 'vue';

export default {
  fetchMatchBrowsers({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/match/browser', params).then((res) => {
      let response = !res ? [] : res;
      response = response.map((item) => {
        item.name = item.d51_title;
        return item;
      });
      commit('SET_MATCH_BROWSERS', response);
      return response;
    });
  },
  fetchMatchOs({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/match/os', params).then((res) => {
      let response = !res ? [] : res;
      response = response.map((item) => {
        item.name = item.d51_title;
        return item;
      });
      commit('SET_MATCH_OS', response);
      return response;
    });
  },
  fetchMatchDevices({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/match/device', params).then((res) => {
      let response = !res ? [] : res;
      response = response.map((item) => {
        item.name = item.d51_title;
        return item;
      });
      commit('SET_MATCH_DEVICES', response);
      return response;
    });
  },
  fetchMatchDeviceTypes({ commit, state }, data = {}) {
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/dict/match/device-type', params).then((res) => {
      let response = !res ? [] : res;
      response = response.map((item) => {
        item.name = item.d51_title;
        return item;
      });
      commit('SET_MATCH_DEVICE_TYPES', response);
      return response;
    });
  }
};
