const advert = {
  SET_ADVERT_CAMPAIGNS(state, payload) {
    state.advertCampaigns = payload;
  },
  SET_RTB_CAMPAIGNS(state, payload) {
    state.rtbCampaigns = payload;
  },
  REPLACE_ADVERT_CREATIVES(state, payload) {
    state.advertCreatives = payload;
  },
  SET_CREATIVE_REJECT_REASONS(state, payload) {
    state.creativeRejectReasons = payload;
  },
  SET_LANDINGS(state, payload) {
    state.landings = payload;
  },
  SET_LABELS(state, payload) {
    state.labels = payload;
  },
  SET_DB_ENTITIES(state, payload) {
    state.dbEntities = payload;
  },
  SET_POSTBACKS(state, payload) {
    state.postbacks = payload;
  },
  SET_RETARGET_GROUPS(state, payload) {
    state.retargetGroups = payload;
  },
  SET_AUTO_RULES(state, payload) {
    state.autoRules = payload;
  },
  SET_HISTORY(state, payload) {
    state.history = payload;
  }
};

const statistics = {
  SET_DIMENSIONS(state, payload) {
    state.dimensions = payload;
  },
  SET_REPORTS(state, payload) {
    state.reports = payload;
  },
  SET_METRICS(state, payload) {
    state.metrics = payload;
  },
  SET_SEGMENTS(state, payload) {
    state.segments = payload;
  }
};

const dictionaries = {
  SET_LANDING_REJECT_REASONS(state, payload) {
    state.landingRejectReasons = payload;
  },
  SET_CAMPAIGN_REJECT_REASONS(state, payload) {
    state.campaignRejectReasons = payload;
  },
  SET_PLACEHOLDERS(state, payload) {
    state.placeholders = payload;
  },
  SET_AD_UNIT_TYPES(state, payload) {
    state.adUnitTypes = payload;
  },
  SET_CONTENT_TYPES(state, payload) {
    state.contentTypes = payload;
  },
  SET_LANDING_GROUPS(state, payload) {
    state.landingGroups = payload;
  },
  SET_REQUEST_FORMAT_TYPES(state, payload) {
    state.requestFormatTypes = payload;
  },
  SET_AD_TAGS(state, payload) {
    state.adTags = payload;
  },
  SET_BID_FORMAT_TYPES(state, payload) {
    state.bidFormatTypes = payload;
  },
  SET_SSP_BID_FORMAT_TYPES(state, payload) {
    state.sspBidFormatTypes = payload;
  },
  SET_CARRIES(state, payload) {
    state.carriers = payload;
  },
  SET_ADVERT_CREATIVE_TAGS(state, payload) {
    state.advertCreativeTags = payload;
  },
  SET_TRIGGER_METHODS(state, payload) {
    state.triggerMethods = payload;
  },
  SET_FEED_PROTOCOLS(state, payload) {
    state.feedProtocols = payload;
  },
  SET_SSP_FEED_PROTOCOLS(state, payload) {
    state.sspFeedProtocols = payload;
  },
  SET_AD_POSITIONS(state, payload) {
    state.adPositions = payload;
  },
  SET_BANNER_DIMENSIONS(state, payload) {
    if (payload && payload.length) {
      payload.forEach((el) => {
        const [w, h] = el.name.split('x');
        el.width = Number(w);
        el.height = Number(h);
      });
    }
    state.bannerDimensions = payload;
  },
  SET_CONNECTION_TYPES(state, payload) {
    state.connectionTypes = payload;
  },
  SET_DEVICE_TYPES(state, payload) {
    state.deviceTypes = payload;
  },
  SET_BROWSER_TYPES(state, payload) {
    state.browserTypes = payload;
  },
  SET_BROWSERS(state, payload) {
    state.browsers = payload;
  },
  SET_DICTIONARY(state, payload) {
    state[payload.exchange][payload.type] = payload.data;
  },
  SET_DEVICE_MODELS(state, payload) {
    state.deviceModels = payload;
  },
  SET_COOKIE_TYPES(state, payload) {
    state.cookieTypes = payload;
  },
  SET_EXCHANGES(state, payload) {
    state.exchanges = payload;
  },
  SET_SITE_CATEGORIES(state, payload) {
    state.siteCategories = payload;
  },
  SET_STATUSES(state, payload) {
    state.statuses = payload;
  },
  SET_TRAFFIC_TYPES(state, payload) {
    state.trafficTypes = payload;
  },
  SET_ADUNIT_FORMATS(state, payload) {
    state.adunitFormats[payload.type] = payload.list;
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload;
  },
  SET_REPEAT_TYPES(state, payload) {
    state.repeatTypes = payload;
  },
  SET_BUDGET_DELIVERY(state, payload) {
    state.budgetDelivery = payload;
  },
  SET_OS_TYPES(state, payload) {
    state.osTypes = payload;
  },
  SET_OS_NAMES(state, payload) {
    state.osNames = payload;
  },
  SET_TARGETS_ADUNITS(state, payload) {
    state.targetsAdunits = payload;
  },
  SET_LANGUAGES_NAMES(state, payload) {
    state.languages = payload;
  },
  SET_DSP_FILTERS(state, payload) {
    state.dspFilters = payload;
  },
  SET_DSP_FEEDS(state, payload) {
    state.dspFeeds = payload;
  },
  SET_DIRECT_DEALS_STATUSES(state, payload) {
    state.directDealsStatuses = payload;
  },
  SET_NOTIFY_TYPES(state, payload) {
    state.notifyTypes = payload;
  },
  SET_ISPS(state, payload) {
    state.isps = payload;
  },
  SET_PRICING_MODELS(state, payload) {
    state.pricingModels = payload;
  },
  SET_LANDING_STATUSES(state, payload) {
    state.landingStatuses = payload;
  },
  SET_CAMPAIGN_STATUSES(state, payload) {
    state.campaignStatuses = payload;
  },
  SET_LANDINGS_DISTRIBUTION(state, payload) {
    state.landingsDistribution = payload;
  },
  SET_TRAFFIC_TYPES_DICT(state, payload) {
    state.trafficTypesDict = payload;
  },
  SET_SITE_TYPES(state, payload) {
    state.siteTypes = payload;
  },
  SET_CPA_STATES(state, payload) {
    state.cpaStates = payload;
  },
  SET_CPA_SPOT_STATES(state, payload) {
    state.cpaSpotStates = payload;
  },
  SET_CPA_RULE_TYPES(state, payload) {
    state.cpaRuleTypes = payload;
  },
  SET_CPA_CONDITIONS(state, payload) {
    state.cpaConditions = payload;
  },
  SET_DEAL_TYPES(state, payload) {
    state.dealTypes = payload;
  },
  SET_BLACKLIST_RULE_STATES(state, payload) {
    state.blacklistRuleStates = payload;
  },
  SET_BLACKLIST_RULE_TYPES(state, payload) {
    state.blacklistRuleTypes = payload;
  },
  SET_BLACKLIST_RULE_CONDITIONS(state, payload) {
    state.blacklistRuleConditions = payload;
  }
};

const match = {
  SET_MATCH_BROWSERS(state, payload) {
    state.matchBrowsers = payload;
  },
  SET_MATCH_OS(state, payload) {
    state.matchOs = payload;
  },
  SET_MATCH_DEVICES(state, payload) {
    state.matchDevices = payload;
  },
  SET_MATCH_DEVICE_TYPES(state, payload) {
    state.matchDeviceTypes = payload;
  }
};

const publisher = {
  SET_ADUNITS_NO_IDS(state, payload) {
    state.adunits = payload;
  },
  SET_ADUNITS(state, payload) {
    state.adunits = payload.map((adunit) => {
      adunit.name = `#${adunit.id} ${adunit.name}`;
      return adunit;
    });
  },
  SET_PUB_SITES(state, payload) {
    state.pubSites = payload.map((site) => {
      site.name = `#${site.id} ${site.name}`;
      return site;
    });
  },
  SET_DIRECT_DEALS(state, payload) {
    state.directDeals = payload;
  },
  SET_SITES_SETTINGS(state, payload) {
    state.sitesSettings = payload;
  },
  SET_SITE_LABELS(state, payload) {
    state.siteLabels = payload;
  },
  SET_BLACKLIST_AUTO_RULES(state, payload) {
    state.blacklistAutoRules = payload;
  }
};

const dspIntegrations = {
  SET_ARBITRAGE_SITES(state, payload) {
    state.arbitrageSites = payload;
  },
  SET_DSP(state, payload) {
    state.dsp = payload;
  },
  SET_SSP(state, payload) {
    state.ssp = payload;
  },
  SET_DSP_FEED_BINDINGS(state, payload) {
    state.dspFeedBindings = payload;
  },
  SET_SSP_FEEDS(state, payload) {
    state.sspFeeds = payload;
  },
  SET_EXCHANGE_POINTS(state, payload) {
    state.exchangePoints = payload;
  },
  SET_SYNDICATION_DOMAINS(state, payload) {
    state.syndicationDomains = payload;
  },
  SET_DSP_DIRECT_FEED_BINDINGS(state, payload) {
    state.dspDirectFeedBindings = payload;
  },
  SET_CODE_TEMPLATES(state, payload) {
    state.codeTemplates = payload;
  },
  SET_EXPERIMENTS(state, payload) {
    state.experiments = payload;
  },
  SET_TRAFFIC_SOURCES(state, payload) {
    state.trafficSources = payload;
  }
};

const mutations = {
  ...advert,
  ...statistics,
  ...dictionaries,
  ...match,
  ...publisher,
  ...dspIntegrations,
  SET_TARGETS(state, payload) {
    state.targets = payload;
  }
};

export default mutations;
