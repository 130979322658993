<template>
  <div>
    <label :for="forAttr" class="m-0 p-0" :class="{ 'w-100': hasAction }">
      <span v-if="label && required" class="text-danger me-1">*</span>
      <span v-if="label">{{ label }}</span>
      <span v-if="textOfError" class="text-danger error-label">
        - {{ textOfError }}
      </span>
      <span v-if="textOfSuccess" class="text-success error-label">
        - {{ textOfSuccess }}
      </span>
      <slot name="action"></slot>
    </label>
  </div>
</template>

<script>
function deepGet(obj, props, defaultValue) {
  if (obj === undefined || obj === null) return defaultValue;
  if (props.length === 0) return obj;

  const foundSoFar = obj[props[0]];
  const remainingProps = props.slice(1);

  return deepGet(foundSoFar, remainingProps, defaultValue);
}

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    for: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    errors: {
      type: [Object, String],
      default: () => ({})
    },
    success: {
      type: [Object, String],
      default: () => ({})
    },
    required: {
      type: Boolean,
      default: false
    },
    hasAction: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    textOfError() {
      if (!this.errors) return '';
      if (typeof this.errors === 'string') return this.errors;

      return deepGet(this.errors, this.field.split('.'), '');
    },
    textOfSuccess() {
      if (!this.success) return '';
      if (typeof this.success === 'string') return this.success;

      return deepGet(this.success, this.field.split('.'), '');
    },
    forAttr() {
      return this.for;
    }
  }
};
</script>
