import { useTitle } from '@vueuse/core';

import config from '../../config/index';

export const useHead = (title) => {
  if (title) {
    useTitle(`${title} - ${config.hostName}`);
  } else {
    useTitle(config.hostName);
  }
};
