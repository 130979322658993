import Vue from 'vue';

import advert from './advert';
import dictionaries from './dictionaries';
import dspIntegrations from './dsp-integrations';
import match from './match';
import publisher from './publisher';
import statistic from './statistics';

const actions = {
  ...dictionaries,
  ...match,
  ...publisher,
  ...advert,
  ...dspIntegrations,
  ...statistic,
  fetchInvoice() {
    return Vue.api.adnet.get('/v0/invoice').then((res) => {
      return res;
    });
  },
  fetchTargets({ commit, state }, data = {}) {
    if (data.refresh === true && !data.paginate) {
      data.params = {
        ...data.params,
        limit: 9999
      };
    }
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/targets', params).then((res) => {
      const responseList = res.result === null ? [] : res.result;
      commit('SET_TARGETS', responseList);
      return res;
    });
  }
};

export default actions;
