<template>
  <div
    ref="row"
    class="header-second-row"
    :class="{
      'is-scrolled': isUp,
      'is-mobile-fixed': isMobileFixed
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isMobileFixed: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      headerOnTop: false,
      scrolled: false,
      topTimeout: null
    };
  },

  methods: {
    scrollHandler() {
      const scrolledPx =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrolledPx <= 0) {
        this.scrolled = false;
      } else {
        this.scrolled = true;
      }
    }
  },

  computed: {
    isUp() {
      return this.headerOnTop || this.scrolled;
    }
  },

  mounted() {
    document.addEventListener('scroll', this.scrollHandler.bind(this));
    this.scrollHandler(this);
    this.$bus.$on('headerDropdown:show', () => {
      this.topTimeout = setTimeout(() => {
        this.headerOnTop = true;
      }, 0);
    });
    this.$bus.$on('headerDropdown:hide', () => {
      if (this.topTimeout) return;
      this.topTimeout = setTimeout(() => {
        this.headerOnTop = false;
      }, 0);
    });
    this.$bus.$on('overlay-on', () => {
      this.topTimeout = setTimeout(() => {
        this.headerOnTop = true;
      }, 0);
    });
    this.$bus.$on('overlay-off', () => {
      if (this.topTimeout) return;
      this.topTimeout = setTimeout(() => {
        this.headerOnTop = false;
      }, 0);
    });
  },

  beforeUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
    this.$bus.$off('headerDropdown:show');
    this.$bus.$off('headerDropdown:hide');
    this.$bus.$off('overlay-on');
    this.$bus.$off('overlay-off');
  }
};
</script>

<style lang="scss">
.header-second-row {
  position: relative;
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  background: $white;
  margin-bottom: 30px;
  z-index: $zindex-fixed;

  &.is-scrolled {
    z-index: 0;
  }

  &.is-mobile-fixed {
    z-index: 1030;
  }
}

@include media-breakpoint-up(md) {
  .header-second-row {
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: -2rem;
  }
}
</style>
