import { onMounted, onUnmounted, reactive } from 'vue';

export default function useScreen() {
  const screenState = reactive({
    xs: false,
    sm: false,
    md: true,
    lg: false,
    xl: false,
    other: false,
    windowWidth: window.innerWidth
  });

  const getScreen = () => {
    const SM = 576;
    const MD = 768;
    const LG = 992;
    const XL = 1368;

    const RATIO = 3;
    const { body } = document;
    const rect = body.getBoundingClientRect();
    let screen = 'other';

    if (rect.width - RATIO < SM) {
      screen = 'xs';
    } else if (rect.width - RATIO < MD && rect.width - RATIO > SM) {
      screen = 'sm';
    } else if (rect.width - RATIO < LG && rect.width - RATIO > MD) {
      screen = 'md';
    } else if (rect.width - RATIO < XL && rect.width - RATIO > LG) {
      screen = 'lg';
    } else if (rect.width - RATIO > XL) {
      screen = 'xl';
    }
    return screen;
  };

  const setScreenState = () => {
    const device = getScreen();
    screenState.xs = device === 'xs';
    screenState.sm = device === 'sm';
    screenState.md = device === 'md';
    screenState.lg = device === 'lg';
    screenState.xl = device === 'xl';
    screenState.other = device === 'other';
    screenState.windowWidth = window.innerWidth;
  };

  const startListeners = () => {
    setScreenState();

    document.addEventListener('visibilitychange', setScreenState);
    window.addEventListener('DOMContentLoaded', setScreenState);
    window.addEventListener('resize', setScreenState);
  };

  const stopListeners = () => {
    document.removeEventListener('visibilitychange', setScreenState);
    window.removeEventListener('DOMContentLoaded', setScreenState);
    window.removeEventListener('resize', setScreenState);
  };

  onMounted(startListeners);
  onUnmounted(stopListeners);

  return {
    screenState
  };
}
