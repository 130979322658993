import { createMongoAbility } from '@casl/ability';

import ABILITIES from './abilityRules';

function detectSubjectType(item) {
  const detectedType =
    !item || typeof item === 'string'
      ? item
      : item.__type || item.constructor.name;
  return detectedType;
}

function defineAbilities(role = 'default') {
  const abilities = getRoleAbilities(role);
  return createMongoAbility(abilities, { detectSubjectType });
}

function getRoleAbilities(role) {
  const abilities = ABILITIES.get(role);
  if (!abilities) {
    console.warn(
      `No abilities found for role: ${role}, returning default abilities`
    );
  }
  return abilities || [];
}

export default { defineAbilities, getRoleAbilities };
