import Vue from 'vue';

export default {
  fetchSegments({ commit, state }, data = {}) {
    if (Object.keys(state.segments).length > 0 && !data.refresh)
      return state.segments;
    const params = data.params ? { params: { ...data.params } } : {};
    return Vue.api.adnet.get('/stats/segments', params).then((res) => {
      const response = !res ? [] : res.segments;
      commit('SET_SEGMENTS', response);
      return response;
    });
  },
  fetchMetrics({ commit }, reportId) {
    return Vue.api.adnet
      .get('/stats/metrics', { params: { code: reportId } })
      .then((res) => {
        commit('SET_METRICS', res.metrics);
      });
  },
  fetchDimensions({ commit }, reportId) {
    return Vue.api.adnet
      .get('/stats/dimensions', { params: { code: reportId } })
      .then((res) => {
        commit('SET_DIMENSIONS', res.dimensions);
      });
  },
  fetchReports({ commit }) {
    return Vue.api.adnet.get('/stats/reports').then((res) => {
      let filteredReports = res.reports.filter(
        (report) => report.categoryCode !== 'hidden'
      );
      commit('SET_REPORTS', filteredReports);
    });
  },
  fetchSubReports({ commit }, reportId) {
    return Vue.api.adnet.get(`/stats/sub-reports/${reportId}`).then((res) => {
      return res['sub_reports'];
    });
  },
  fetchMultizones({ commit }) {
    return Vue.api.adnet
      .get('/stats/zones/multi-zones')
      .then((res) => {
        return res.zones || [];
      })
      .catch((e) => {
        return [];
      });
  },
  fetchStatistics: (function () {
    let cancelStatisticFetching;
    return function (arg, params) {
      if (typeof cancelStatisticFetching === 'function') {
        cancelStatisticFetching();
      }

      let queryParams = {
        q: params.q
      };
      if (params.paginate) {
        Object.keys(params.paginate).forEach((key) => {
          queryParams[key] = params.paginate[key];
        });
      }

      return Vue.api.adnet
        .get('/stats/grouped', {
          params: queryParams,
          cancelToken: new Vue.api.client.CancelToken(function executor(c) {
            cancelStatisticFetching = c;
          })
        })
        .then((res) => {
          const data = res.stats.map((el) => {
            return {
              _keys: el.keys,
              ...el.dimensions,
              ...el.metrics
            };
          });
          data.forEach((el) => {
            params.q.groups.forEach((group) => {
              if (el[group] === undefined) el[group] = 'None';
            });
            return el;
          });
          return {
            data: data || [],
            totals: res.totals || {},
            sql: res.querySql || [],
            metadata: res.metadata || null
          };
        })
        .catch((error) => {
          if (!Vue.api.client.isCancel(error)) {
            throw error;
          }
        });
    };
  })(),
  fetchStatisticsPOST: (function () {
    let cancelStatisticFetching;
    return function (arg, params) {
      if (typeof cancelStatisticFetching === 'function') {
        cancelStatisticFetching();
      }

      return Vue.api.adnet.post('/stats/grouped', params).then((res) => {
        const data = res.stats.map((el) => {
          return {
            _keys: el.keys,
            ...el.dimensions,
            ...el.metrics
          };
        });

        data.forEach((el) => {
          params.groups.forEach((group) => {
            if (el[group] === undefined) el[group] = 'None';
          });
          return el;
        });

        return {
          data: data || [],
          totals: res.totals || {},
          sql: res.querySql || [],
          metadata: res.metadata || null
        };
      });
    };
  })(),
  // this is temporary
  fetchStatisticsBid: (function () {
    let cancelStatisticFetching;
    return function (arg, params) {
      if (typeof cancelStatisticFetching === 'function') {
        cancelStatisticFetching();
      }

      return Vue.api.adnet.post('/stats/grouped', params).then((res) => {
        const data = res.stats.map((el) => {
          return {
            _keys: el.keys,
            ...el.dimensions,
            ...el.metrics
          };
        });
        if (params.groups) {
          data.forEach((el) => {
            params.groups.forEach((group) => {
              if (el[group] === undefined) el[group] = 'None';
            });
            return el;
          });
        }

        return {
          data: data || [],
          totals: res.totals || {},
          sql: res.querySql || [],
          metadata: res.metadata || null
        };
      });
    };
  })(),
  fetchStatisticsNoCancel(arg, params) {
    return Vue.api.adnet
      .get('/stats/grouped', {
        params: params
      })
      .then((res) => {
        const data = res.stats.map((el) => {
          return {
            _keys: el.keys,
            ...el.dimensions,
            ...el.metrics
          };
        });
        data.forEach((el) => {
          params.q.groups.forEach((group) => {
            if (el[group] === undefined) el[group] = 'None';
          });
          return el;
        });
        return {
          data: data || [],
          totals: res.totals || {},
          sql: res.querySql || [],
          metadata: res.metadata || null
        };
      });
  }
};
