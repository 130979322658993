import createPermission from '@/permission';
import * as auth from '@/utils/auth';

import { whiteList as permissionWhiteList } from './permission';
import addRouterStateHandler from './router/routerStateHandler';

export default async function initializeApp({
  store,
  router,
  userSettings,
  config
}) {
  if (
    !auth.readToken() &&
    permissionWhiteList.indexOf(window.location.pathname) === -1
  ) {
    window.localStorage.setItem('entryUrl', window.location.pathname);
    store.commit('SET_ENTRY_URL', window.location.pathname);
    await router.replace({ name: 'login' });
  }

  let waitGroup = [];

  waitGroup.push(store.dispatch('fetchGrants'));

  if (auth.readToken()) {
    waitGroup.push(store.dispatch('fetchUser'));
    waitGroup.push(userSettings.init());
    await Promise.all(waitGroup);
  }

  if (!auth.readActiveRole() && auth.readToken()) {
    const adminIndex = store.state.app.user.roles.indexOf(
      config.rolesIds['admin']
    );

    store.dispatch(
      'switchRole',
      store.state.app.user.roles[adminIndex] || store.state.app.user.roles[0]
    );
  } else {
    store.dispatch('switchRole', auth.readActiveRole());
  }

  createPermission({ store, router });

  addRouterStateHandler(router);
}
