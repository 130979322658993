const Sites = () => import('@/views/Publisher/Sites/List.vue');
const SiteForm = () => import('@/views/Publisher/Sites/Form.vue');
const SiteLabels = () => import('@/views/Publisher/SiteLabels/List.vue');

const routes = [
  {
    path: '/sites',
    component: Sites,
    name: 'sites',
    meta: {
      roles: ['publisher'],
      title: 'Sites',
      icon: 'menu-sites',
      alias: 's'
    }
  },
  {
    path: '/site-add',
    component: SiteForm,
    name: 'site-add',
    hidden: true,
    meta: {
      roles: ['publisher', 'advertiser', 'advert-admin', 'manager'],
      title: 'Add site',
      alias: 's:a'
    }
  },
  {
    path: '/site-edit/:id',
    component: SiteForm,
    name: 'site-edit',
    hidden: true,
    meta: {
      roles: ['publisher', 'advertiser'],
      title: 'Edit site'
    }
  },
  {
    path: '/site-labels',
    component: SiteLabels,
    name: 'site-labels',
    hidden: true,
    meta: {
      title: 'Site labels',
      roles: ['publisher']
    }
  }
];

export default routes;
